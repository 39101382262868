import { FC } from 'react';
import styled from 'styled-components';
import { Wrapper } from './Wrapper';
import Img from './Img';

export const ImageWrapper = styled(Wrapper)`
  background: ${({ theme }) => theme.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.mischka};
  padding: 4px;
  position: relative;

  > img {
    height: 38px;
    width: 38px;
    object-fit: contain;
  }
`;

interface ImageBoxProps {
  imageUrl: string;
  alt?: string;
  onClick?: () => void;
}

const ImageBox: FC<ImageBoxProps> = ({ imageUrl, alt = '', onClick }) => {
  return (
    <ImageWrapper flex center onClick={onClick}>
      <Img src={imageUrl} alt={alt} />
    </ImageWrapper>
  );
};

export default ImageBox;
