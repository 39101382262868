import { RootState } from 'store';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  getFormsAndCatalogs,
  setFormsAndCatalogs,
} from 'overview/store/overviewActions';

import { ButtonPrimary } from 'shared/components/Button';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { nevada } from 'shared/config/Colors';
import { P } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import EmptyState from 'shared/components/EmptyState';
import Loader from 'shared/components/Loader';
import RadioButtonWithDesc from 'shared/components/RadioButtonWithDesc';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';

const BrowseFormsContainer = styled.div``;

const HiddenLink = styled.a``;

interface FormsForm {
  formUrl: string;
}

const BrowseForms = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();

  const [modalOpened, setModalOpened] = useState(false);

  const [formsLoading, setFormsLoading] = useState(true);

  const hiddenLinkRef = useRef<HTMLAnchorElement>(null);

  const forms = useSelector((state: RootState) => state.overviewReducer.forms);

  const { register, handleSubmit, formState, reset, watch } =
    useForm<FormsForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        formUrl: '',
      },
    });

  const formUrlWatched = watch('formUrl');

  const onFormSelect = (data: FormsForm, close: () => void) => {
    if (hiddenLinkRef.current) {
      hiddenLinkRef.current.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    }

    reset();
    close();
  };

  useEffect(() => {
    if (modalOpened) {
      setFormsLoading(true);
      dispatch(getFormsAndCatalogs(setFormsLoading));
    } else {
      dispatch(
        setFormsAndCatalogs({ catalogs: null, forms: null, howToCatalog: null })
      );
    }

    return () => {
      reset();
    };
  }, [modalOpened]);

  return (
    <BrowseFormsContainer>
      <HiddenLink
        ref={hiddenLinkRef}
        hidden
        href={formUrlWatched}
        target="_blank"
        rel="noopener"
      />
      <ModalWrapper
        modal
        lockScroll
        onOpen={() => setModalOpened((prevState) => !prevState)}
        onClose={() => setModalOpened((prevState) => !prevState)}
        trigger={<div>{children}</div>}
      >
        {(close: () => void) => (
          <Modal title="Forms" confirm close={close}>
            <P color={nevada}>Please select a form to view</P>

            <Spacer h="30px" />

            <ScrollbarsCustom
              autoHide
              autoHideDuration={300}
              autoHideTimeout={500}
              autoHeight
              autoHeightMin={495}
              autoHeightMax={495}
            >
              {!formsLoading &&
                forms?.map((form) => (
                  <RadioButtonWithDesc
                    {...register('formUrl', { required: true })}
                    data-flow-option
                    id={form.id}
                    key={form.id}
                    label={form.name}
                    value={form.fileUrl}
                  />
                ))}

              {!formsLoading && !forms && (
                <EmptyState
                  marginTop={10}
                  marginBottom={30}
                  title="There are no forms"
                />
              )}

              {formsLoading && <Loader size={42} />}
            </ScrollbarsCustom>

            <Spacer h="30px" />

            <Wrapper flex justifyEnd>
              <ButtonPrimary
                type="button"
                onClick={handleSubmit((data: FormsForm) =>
                  onFormSelect(data, close)
                )}
                disabled={!formState.isValid || !forms?.length}
              >
                Open Form
              </ButtonPrimary>
            </Wrapper>
          </Modal>
        )}
      </ModalWrapper>
    </BrowseFormsContainer>
  );
};

export default BrowseForms;
