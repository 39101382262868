import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';

import { H2, P } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { useEffect } from 'react';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { getDealershipPreferences } from 'order/store/orderActions';

const DealershipPreferencesContainer = styled.div``;

const DealershipPreference = styled.div`
  margin-bottom: 20px;
  font-size: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const DealershipPreferences = () => {
  const dispatch = useAppDispatch();

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const dealershipPreferences = useSelector(
    (state: RootState) => state.orderReducer.dealershipPreferences
  );

  useEffect(() => {
    if (order) {
      dispatch(
        getDealershipPreferences({
          dealershipId: order.dealershipId!,
        })
      );
    }
  }, [order]);

  return (
    <DealershipPreferencesContainer>
      <H2>Dealer Preferences</H2>

      <Spacer h="20px" />

      <Wrapper maxWidth={600}>
        {!dealershipPreferences?.length && (
          <P>No Dealership preferences at this moment.</P>
        )}

        {dealershipPreferences?.map((dp) => (
          <DealershipPreference key={uuidv4()}>{dp}</DealershipPreference>
        ))}
      </Wrapper>
    </DealershipPreferencesContainer>
  );
};

export default DealershipPreferences;
