import { FC } from 'react';
import styled from 'styled-components';

import { FieldName } from 'order/shared/ackchangelog/interface/AckChangeLogItem';
import { Wrapper } from 'shared/components/Wrapper';
import { P, PSmall } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { silverChaliceTwo } from 'shared/config/Colors';
import { ButtonIcon } from 'shared/components/Button';

export const CloseButton = styled(ButtonIcon)`
  path {
    fill: ${({ theme }) => theme.mineShaftVariation};
  }

  &:hover {
    path {
      fill: ${({ theme }) => theme.lynch};
    }
  }
`;

export const DoorCodesTableRowT = styled(Wrapper)`
  > *:first-child {
    width: 120px;
  }
`;

export const DoorCodesTableRowContentT = styled(Wrapper)`
  > *:not(span) {
    width: 80px;
    text-align: right;
  }
`;

export const Container = styled(Wrapper)`
  height: calc(100vh - 260px);
  overflow: auto;
  padding: 24px 16px;
`;

interface StyleDetailsFieldProps {
  fieldLabel: string;
  fieldValue?: string | number;
  fieldName: FieldName;
  styleId: string;
  asNote?: boolean;
}

export const StyleDetailsField: FC<StyleDetailsFieldProps> = ({
  fieldLabel,
  fieldValue,
  asNote,
}) => {
  return (
    <Wrapper
      wFull
      maxWidth={asNote ? undefined : 264}
      between
      alignStart
      marginRight={120}
      style={{ paddingBottom: '16px' }}
    >
      <Wrapper minWidth={160}>
        <P color={silverChaliceTwo} fontSize={14}>
          {fieldLabel}
        </P>
      </Wrapper>

      <Spacer h="4px" />

      <PSmall
        style={
          asNote
            ? {
                backgroundColor: '#e3aa5320',
                padding: '2px 6px',
                fontWeight: 600,
              }
            : { fontWeight: 600, maxWidth: '150px' }
        }
      >
        {fieldValue ?? ''}
      </PSmall>
    </Wrapper>
  );
};
