export enum FormCatalogTypeEnum {
  CATALOG = 1,
  FORM,
  PRICING_INFO,
  HOW_TO,
}

export interface FormAndCatalog {
  name: string;
  id: string;
  description: string;
  fileUrl: string;
  type: FormCatalogTypeEnum;
}
