import styled from 'styled-components';
import { FC, useState } from 'react';

import { CommentAttachmentDownload } from 'shared/components/CommentAttachmentDownload';
import { H5, P } from 'shared/components/Typography';
import { nevada } from 'shared/config/Colors';
import { RoleLabel } from 'shared/components/RoleLabel';
import { Wrapper } from 'shared/components/Wrapper';
import AvatarCircle from 'shared/components/AvatarCircle';
import HangingNote from 'shared/components/HangingNote';
import UtilService from 'shared/services/util.service';

import {
  CurtainComment,
  CurtainCommentAttachment,
} from 'shared/interface/CurtainComment';
import { ButtonIcon } from 'shared/components/Button';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-nicer.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import { Tooltip } from 'shared/components/Tooltip';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import {
  deleteComment,
  getCurtainComments,
  setUpdatedComment,
} from 'curtain/store/curtainActions';
import { DeleteCommentRequest } from 'curtain/interfaces/CurtainCommentActionPayload';
import { ICurtainTopic } from 'curtain/interfaces/ICurtainTopic';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';

interface CurtainCommentCardProps {
  data: CurtainComment;
  isUserCSR?: boolean;
}

const CurtainCommentContainer = styled.div<{
  isRead: boolean;
  isUserCSR?: boolean;
}>`
  border-bottom: 1px solid ${({ theme }) => theme.alto};
  padding: 12px 25px 25px 16px;

  &:last-child {
    border-bottom: none;
  }
  background-color: ${({ isRead, isUserCSR }) =>
    !isRead && isUserCSR ? '#FBFBFB' : ''};
`;

const AvatarContainer = styled.div`
  margin-right: 13px;
`;

const CommentSection = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
`;

const CollaborationHeadingMetaLead = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
`;

const CollaborationHeadingMetaSecondary = styled.div`
  color: ${({ theme }) => theme.silverChalice};
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
`;

const CurtainCommentBody = styled.div`
  font-size: 12px;
  margin-top: 10px;
  line-height: 16px;
  color: ${({ theme }) => theme.nevada};
  font-weight: 400;
`;

const CommentAttachments = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0px 2px 0px;
`;

const RemoveTopicButton = styled(ButtonIcon)`
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.silverSand};
  bottom: 16px;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;

  &:focus:not(:hover):not(:active) {
    border-radius: 50%;
  }

  &:hover {
    ${Tooltip} {
      opacity: 1;
    }
  }
`;

const EditTopicButton = styled(ButtonIcon)`
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.silverSand};
  bottom: 16px;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  margin-right: 5px;
  &:focus:not(:hover):not(:active) {
    border-radius: 50%;
  }

  &:hover {
    ${Tooltip} {
      opacity: 1;
    }
  }
`;

const ButtonsWrapper = styled(Wrapper)`
  margin-left: auto;
`;

export const CurtainCommentCard: FC<CurtainCommentCardProps> = ({
  data,
  isUserCSR,
}) => {
  const dispatch = useAppDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const curtainActiveTopic: ICurtainTopic = useSelector(
    (state: RootState) => state.curtainReducer.curtainActiveTopic
  );

  const onDeleteCommentSuccess = () => {
    toast.success('Comment has been deleted successfully');
    dispatch(
      getCurtainComments(
        curtainActiveTopic?.id,
        () => false,
        () => toast.error('Failed to fetch comments.')
      )
    );
  };
  const onDeleteCommentHandler = () => {
    const deleteData: DeleteCommentRequest = {
      commentId: data.id,
      topicId: curtainActiveTopic.id,
    };
    setOpenDeleteModal(false);

    dispatch(deleteComment(deleteData, onDeleteCommentSuccess));
  };
  return (
    <CurtainCommentContainer isRead={data.isRead} isUserCSR={isUserCSR}>
      <Wrapper flex justifyStart>
        <AvatarContainer>
          <AvatarCircle
            user={{
              firstName: data.user.firstName,
              imageUrl: data.user.imageUrl,
              lastName: data.user.lastName,
              roles: data.user.roles,
            }}
            size={28}
          />
        </AvatarContainer>
        <CommentSection>
          <CollaborationHeadingMetaLead>
            <Wrapper flex maxWidth={120}>
              <H5>
                {data.user.firstName} {data.user.lastName}
              </H5>
            </Wrapper>
            <RoleLabel userRole={data.user.roles[0]}>
              {data.user.roles[0]}
            </RoleLabel>

            {data.changeRequested && <HangingNote mlAuto />}
            {isUserCSR && (
              <ButtonsWrapper flex>
                <EditTopicButton
                  onClick={() => dispatch(setUpdatedComment(data))}
                >
                  <Tooltip position="left">Click to edit the comment.</Tooltip>
                  <EditIcon />
                </EditTopicButton>
                <RemoveTopicButton onClick={() => setOpenDeleteModal(true)}>
                  <Tooltip position="left">
                    Click to remove the comment.
                  </Tooltip>
                  <TrashIcon />
                </RemoveTopicButton>
              </ButtonsWrapper>
            )}
          </CollaborationHeadingMetaLead>

          <CollaborationHeadingMetaSecondary>
            {UtilService.formatDate({ date: data.createdOnUtc })}
            {data.private && ' (Private)'}
          </CollaborationHeadingMetaSecondary>

          <CurtainCommentBody>
            <P fontSize={12} color={nevada} wordBreak="break-word">
              {data.content}
            </P>
          </CurtainCommentBody>

          <CommentAttachments>
            {data.attachments
              ? data.attachments.map((attachment) => (
                  <CommentAttachmentDownload
                    key={`${attachment.size}--${attachment.name}`}
                    fileId={(attachment as CurtainCommentAttachment).fileId}
                    fileName={attachment.name}
                  />
                ))
              : null}
          </CommentAttachments>
        </CommentSection>
      </Wrapper>
      <ConfirmationModal
        confirm={onDeleteCommentHandler}
        buttonText="Delete"
        cancel={() => setOpenDeleteModal(false)}
        title="Are you sure?"
        message="This comment will be permanently removed."
        opened={openDeleteModal}
      />
    </CurtainCommentContainer>
  );
};
