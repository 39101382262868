import styled from 'styled-components';
import {
  ChangeEvent,
  useState,
  useEffect,
  memo,
  ClipboardEvent,
  DragEvent,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { ReactComponent as AttachmentIcon } from 'assets/icons/attachment.svg';

import { SelectCommentAttachmentMenu } from 'curtain/components/SelectCommentAttachmentMenu';
import { TopicType } from 'curtain/enums/TopicTypeEnum';

import {
  CreateTopicRequest,
  IAttachment,
  UpdateTopicRequest,
} from 'curtain/interfaces/CreateTopicRequest';

import {
  createTopic,
  isAddingTopic,
  setActiveChannel,
  setActiveEntityID,
  setActiveLineItemID,
  setActiveStyleID,
  setCreateTopic,
  setTopicItemDefaultValue,
  setTopicTypeDefaultValue,
  setUpdatedTopic,
  updateTopic,
  uploadFiles,
} from 'curtain/store/curtainActions';

import { LineItem } from 'order/wizard/orderStyles/interface/LineItem';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { Style } from 'order/wizard/orderStyles/interface/Style';

import {
  getOrderLineItems,
  getOrderStyles,
  updateLineItemNoOfTopics,
  updateStyleNoOfTopics,
} from 'order/store/orderActions';

import FormError from 'shared/components/FormError';
import Loader from 'shared/components/Loader';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import UtilService from 'shared/services/util.service';
import { CommentAttachment } from 'shared/components/CommentAttachment';
import { FileUploadResponse } from 'shared/interface/serverResponses/FileUploadResponse';
import { Form } from 'shared/components/Form';
import { FormLabel } from 'shared/components/FormLabel';
import { MenuWithDropdown } from 'shared/components/MenuWithDropdown';
import { Select } from 'shared/components/Select';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { Spacer, Divider } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { deleteFile } from 'shared/store/sharedActions';
import { orderHeaderHeightWithCollaborationActions } from 'shared/config/Variables';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useCanEditOrder } from 'shared/hooks/useCanEditOrder';
import { useOrderProductType } from 'shared/hooks/useOrderProductType';
import { usePriorityChange } from 'shared/hooks/usePriorityChange';

import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonAttachmentIcon,
} from 'shared/components/Button';

import {
  topicCommentValidation,
  topicDescriptionValidation,
} from 'shared/validations/validations';

import { ICurtainTopic } from 'curtain/interfaces/ICurtainTopic';

import { Mention, MentionsInput } from 'react-mentions';
import { useMentionInput } from 'curtain/hooks/useMentionInput';
import { useBrowserStorage } from 'shared/hooks/useBrowserStorage';
import { getChannelsAndTopics } from '../../order/wizard/topic/store/TopicActions';
import mentions from '../mentions/mentions.module.css';

const FormContainer = styled.div<{ canEditOrder: boolean }>`
  overflow-y: auto;
  height: calc(
    100vh -
      ${(canEditOrder) =>
        orderHeaderHeightWithCollaborationActions - (canEditOrder ? 0 : 44)}px
  );
`;

const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px 20px 20px;
`;

const CommentAttachmentsContainer = styled(Wrapper)`
  margin-bottom: 12px;
`;

const ActionsContainer = styled(Wrapper)`
  /* height: 100%; */
  flex: 1;
`;

interface FormProps {
  comment: string;
  item: string;
  name: string;
  topicType: string;
  topicItem: string;
}

interface CurtainCreateNewTopicFormProps {
  isPrivateTopicChecked: boolean;
  topic: ICurtainTopic | null;
}

export const CurtainCreateNewTopicForm = memo(
  ({ isPrivateTopicChecked, topic }: CurtainCreateNewTopicFormProps) => {
    const dispatch = useAppDispatch();

    const regex = /[^{}]+(?=})/g;

    const canEditOrder = useCanEditOrder();

    const { orderId } = useParams<OrderPageParams>();

    const { styleChannels, lineItemChannels, otherTopics } = useSelector(
      (state: RootState) => state.topicReducer
    );

    const addingTopic = useSelector(
      (state: RootState) => state.curtainReducer.addingTopic
    );

    const topicTypeDefaultValue = useSelector(
      (state: RootState) => state.curtainReducer.topicTypeDefaultValue
    );

    const topicItemDefaultValue = useSelector(
      (state: RootState) => state.curtainReducer.topicItemDefaultValue
    );

    const orderLineItems = useSelector(
      (state: RootState) => state.orderReducer.lineItems
    );

    const styles = useSelector((state: RootState) => state.orderReducer.styles);

    const isOrderSalesMaterial = useOrderProductType(
      OrderStylizationTypeEnums.SALES_MATERIAL
    );

    const customProductLine = useSelector((state: RootState) =>
      state.sharedReducer.productLines?.find(
        (productLine) =>
          productLine.name === ProductLineEnums.PRODUCT_LINE_CUSTOM
      )
    );

    const activeChannel = useSelector(
      (state: RootState) => state.curtainReducer.activeChannel
    );

    const {
      handleSubmit,
      getValues,
      setValue,
      watch,
      control,
      formState: { errors, isDirty, isSubmitting },
      reset: resetForm,
    } = useForm<FormProps>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues: {
        comment: '',
        item: '',
        name: '',
        topicItem: '',
        topicType: '',
      },
    });

    const {
      setItem,
      value: formData,
      removeItem,
    } = useBrowserStorage({
      key: `newtopic_${activeChannel?.id ?? '0'}`,
      storageType: 'sessionStorage',
    });

    const topicTypeWatched = watch('topicType');
    const topicItemWatched = watch('topicItem');
    const topicNameWatched = watch('name');
    const commentWatched = watch('comment');
    const watchAllFields = watch();

    const [commentFiles, setCommentFiles] = useState<File[] | null>(null);

    const [itemOptions, setItemOptions] = useState<SelectOptionProps[] | null>(
      null
    );

    const [itemOptionsLoading, setItemOptionsLoading] = useState(false);

    const { changeEntityPriority } = usePriorityChange();

    const topicTypeOptions: SelectOptionProps[] = [
      {
        value: TopicType.STYLE,
        label: 'Style',
      },
      {
        value: TopicType.LINE_ITEM,
        label: 'Line Item',
      },
      {
        value: TopicType.OTHER,
        label: 'Other',
      },
    ];

    const [userIds, setUserIds] = useState<string[]>([]);
    const [topicUserIds, setTopicUserIds] = useState<string[]>([]);

    const handleFileInput = (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;

      if (files != null && files.length > 0) {
        const validatedFileList = UtilService.validateFiles(files);

        setCommentFiles((prevState) =>
          prevState ? [...prevState, ...validatedFileList] : validatedFileList
        );
      } else {
        toast.error("Couldn't use the selected file");
      }
    };

    const onDropboxFileSelectedHandler = (file: File) => {
      const newFiles: File[] = [];
      newFiles.push(file);

      setCommentFiles((prevState) =>
        prevState ? [...prevState, ...newFiles] : newFiles
      );
    };

    const deleteSingleAttachment = (index: number) => {
      setCommentFiles([
        ...commentFiles!.filter(
          (comment, commentIndex) => commentIndex !== index
        ),
      ]);
    };

    const refetchTopics = () => {
      if (orderId) {
        const getStyleTopics =
          !styleChannels || topicTypeWatched === TopicType.STYLE;
        const getLineItemTopics =
          !lineItemChannels || topicTypeWatched === TopicType.LINE_ITEM;

        dispatch(
          getChannelsAndTopics({
            orderId,
            getStyleTopics,
            getLineItemTopics,
            getOtherTopics:
              !otherTopics || topicTypeWatched === TopicType.OTHER,
          })
        );
      }
    };

    const onSuccessCreateTopic = () => {
      dispatch(isAddingTopic(false));
      dispatch(setCreateTopic(false));
      dispatch(setUpdatedTopic(null));
      removeItem();

      const entityId =
        topicTypeWatched !== TopicType.OTHER ? topicItemWatched : null;

      // trigger priority only if private not checked.
      if (!isPrivateTopicChecked) {
        changeEntityPriority(entityId, topicTypeWatched as TopicType);
      }

      const numberOfTopics =
        (orderLineItems ?? []).find(
          (orderLineItem) => orderLineItem.lineItemId === entityId
        )?.numberOfTopics ?? null;

      const topicCount =
        (styles ?? []).find((style) => style.id === entityId)?.topicCount ??
        null;

      if (entityId) {
        if (
          topicTypeWatched &&
          topicTypeWatched === TopicType.STYLE &&
          topicCount !== null
        ) {
          dispatch(
            updateStyleNoOfTopics({ id: entityId, topicCount: topicCount + 1 })
          );
        } else {
          dispatch(
            updateLineItemNoOfTopics({
              lineItemId: entityId,
              topicCount: (numberOfTopics ?? 0) + 1,
            })
          );
        }
      }

      refetchTopics();
      toast.success('New topic has been successfully created.');
    };

    const onFailedCreateTopic = (attachments: IAttachment[]) => {
      dispatch(isAddingTopic(false));
      removeItem();

      attachments.forEach((attachment) =>
        dispatch(deleteFile(attachment.fileId))
      );

      toast.error("New topic hasn't been successfully created.");
    };

    const onSuccessUpdateTopic = () => {
      dispatch(isAddingTopic(false));
      dispatch(setCreateTopic(false));
      dispatch(setUpdatedTopic(null));
      removeItem();

      refetchTopics();
      toast.success('Topic has been successfully updated.');
    };

    const onSuccessfulAttachmentUpload = (
      attachments: FileUploadResponse[]
    ) => {
      const data = getValues();
      if (topic == null) {
        const createTopicData: CreateTopicRequest = {
          orderId,
          type: Number(topicTypeWatched),
          entityId:
            topicTypeWatched !== TopicType.OTHER ? topicItemWatched : null,
          name: data.name.replace(regex, '').replace(/{}/g, ''),
          private: isPrivateTopicChecked,
          comment: {
            content: data.comment.replace(regex, '').replace(/{}/g, ''),
            private: isPrivateTopicChecked,
            attachments: attachments.map((attachment) => {
              return { fileId: attachment.id, private: isPrivateTopicChecked };
            }),
            userIds,
          },
          userIds: topicUserIds,
        };

        dispatch(
          createTopic(
            createTopicData,
            onSuccessCreateTopic,
            onFailedCreateTopic
          )
        );
      } else {
        const updateTopicData: UpdateTopicRequest = {
          orderId,
          topicId: topic.id,
          name: data.name.replace(regex, '').replace(/{}/g, ''),
          private: isPrivateTopicChecked,
          ...(data.comment && {
            comment: {
              content: data.comment.replace(regex, '').replace(/{}/g, ''),
              private: isPrivateTopicChecked,
              attachments: attachments.map((attachment) => {
                return {
                  fileId: attachment.id,
                  private: isPrivateTopicChecked,
                };
              }),
              userIds,
            },
          }),
        };

        dispatch(
          updateTopic(
            updateTopicData,
            onSuccessUpdateTopic,
            onFailedCreateTopic
          )
        );
      }
    };

    const onFailedAttachmentUpload = () => {
      dispatch(isAddingTopic(false));
      toast.error(
        'There was an error while trying to upload the attached file(s).'
      );
    };

    const onSuccessGetOrderStyles = (orderStyles: Style[]) => {
      const stylesWithName = orderStyles.filter(
        (channel) => channel.name != null && !channel.isDeleted
      );
      const data: SelectOptionProps[] = stylesWithName.map((style) => ({
        value: style.id!,
        label: `#${style.number} - ${style.name}`,
      }));

      setItemOptions(data);
    };

    const generateLineItemLabel = (lineItem: LineItem) => {
      const label = `#${lineItem.number} - ${lineItem.lineItemDetails.code!}
     ${
       lineItem.clientLineItemNumber
         ? ` (CL #${lineItem.clientLineItemNumber})`
         : ''
     }
      (${lineItem.pwwNumber})`;

      return label;
    };

    const onSuccessGetOrderLineItems = (lineItems: LineItem[]) => {
      const data: SelectOptionProps[] = lineItems.map((lineItem) => ({
        value: lineItem.lineItemId!,
        label: generateLineItemLabel(lineItem),
      }));

      setItemOptions(data);
    };

    const isItemInputDisabled = () =>
      itemOptions == null || itemOptions.length === 0 || topic;

    const isSubmitDisabled = () => {
      if (addingTopic || !topicTypeWatched) {
        return true;
      }

      if (topicTypeWatched !== TopicType.OTHER && !topicItemWatched) {
        return true;
      }

      return false;
    };

    const selectTopicItemPlaceholder = () => {
      if (itemOptionsLoading) {
        return 'Items are loading...';
      }

      if ((itemOptions?.length ?? 0) > 0 && !itemOptionsLoading) {
        return 'Select an Item';
      }

      return 'No Available Items';
    };

    const onSubmit = (data: FormProps) => {
      dispatch(isAddingTopic(true));
      const entityId =
        topicTypeWatched !== TopicType.OTHER ? topicItemWatched : null;
      if (commentFiles == null || commentFiles.length === 0) {
        if (topic !== null) {
          const updateTopicData: UpdateTopicRequest = {
            orderId,
            topicId: topic.id,
            name: data.name.replace(regex, '').replace(/{}/g, ''),
            private: isPrivateTopicChecked,
            ...(data.comment && {
              comment: {
                content: data.comment.replace(regex, '').replace(/{}/g, ''),
                private: isPrivateTopicChecked,
                attachments: [],
                userIds,
              },
            }),
          };

          dispatch(
            updateTopic(
              updateTopicData,
              onSuccessUpdateTopic,
              onFailedCreateTopic
            )
          );
        } else {
          const createTopicData: CreateTopicRequest = {
            orderId,
            type: Number(topicTypeWatched),
            entityId,
            name: data.name.replace(regex, '').replace(/{}/g, ''),
            private: isPrivateTopicChecked,
            ...(data.comment && {
              comment: {
                content: data.comment.replace(regex, '').replace(/{}/g, ''),
                private: isPrivateTopicChecked,
                attachments: [],
                userIds,
              },
            }),
            userIds: topicUserIds,
          };

          dispatch(
            createTopic(
              createTopicData,
              onSuccessCreateTopic,
              onFailedCreateTopic
            )
          );
        }
      } else {
        dispatch(
          uploadFiles(
            commentFiles,
            (uploadedFiles: FileUploadResponse[]) =>
              onSuccessfulAttachmentUpload(uploadedFiles),
            onFailedAttachmentUpload
          )
        );
      }
    };

    const reset = () => {
      dispatch(setTopicTypeDefaultValue(null));
      dispatch(setTopicItemDefaultValue(null));

      dispatch(setActiveStyleID(null));
      dispatch(setActiveLineItemID(null));
      dispatch(setActiveEntityID(null));
      dispatch(setActiveChannel(null));
    };

    useEffect(() => {
      if (!isDirty && topicTypeDefaultValue) {
        setValue('topicType', topicTypeDefaultValue.value);
      }
    }, [topicTypeDefaultValue]);

    useEffect(() => {
      if (!isDirty && topicItemDefaultValue) {
        setValue('topicItem', topicItemDefaultValue.value);
      }
    }, [topicItemDefaultValue]);

    useEffect(() => {
      if (orderId) {
        if (isDirty) {
          setItemOptions(null);
          setValue('topicItem', '');

          reset();
        }

        // pull options based on selected topic type
        if (topicTypeWatched === TopicType.STYLE) {
          if (!styles) {
            setItemOptionsLoading(true);
            dispatch(
              getOrderStyles(
                orderId,
                setItemOptionsLoading,
                onSuccessGetOrderStyles
              )
            );
          } else {
            onSuccessGetOrderStyles(styles);
          }
        } else if (topicTypeWatched === TopicType.LINE_ITEM) {
          if (!orderLineItems) {
            setItemOptionsLoading(true);
            dispatch(
              getOrderLineItems(
                {
                  orderId,
                  ...(isOrderSalesMaterial && {
                    isSalesMaterial: true,
                    productLineId: customProductLine?.id,
                  }),
                },
                setItemOptionsLoading,
                onSuccessGetOrderLineItems
              )
            );
          } else {
            onSuccessGetOrderLineItems(orderLineItems);
          }
        }
      }
    }, [topicTypeWatched]);

    useEffect(() => {
      if (isDirty) {
        reset();
      }
    }, [topicItemWatched]);

    const handlePaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
      if (e.clipboardData && e.clipboardData.files.length) {
        const file = e.clipboardData.files[0];
        if (file && file.type.includes('image')) {
          setCommentFiles((prevState) => [...(prevState ?? []), file]);
        }
      }
    };

    const handleDrop = (e: DragEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
      const { files } = e.dataTransfer;
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        setCommentFiles((prevState) => [...(prevState ?? []), file]);
      }
    };

    const handleDragOver = (e: DragEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
    };

    useEffect(() => {
      if (topic) {
        setValue('name', topic?.name ?? '');
      }
    }, [topic]);

    useEffect(() => {
      if (formData && !topic) {
        resetForm(formData as FormProps);
      }
    }, [formData]);

    useEffect(() => {
      if (!topic && isDirty) {
        const data = getValues();
        setItem(data);
      }
      if (isSubmitting) {
        removeItem();
      }
    }, [watchAllFields]);

    const selectedUsers = useMentionInput();

    useEffect(() => {
      if (topicNameWatched) {
        if (topicNameWatched.match(regex)) {
          const ids = topicNameWatched.match(regex) as string[];
          setTopicUserIds([...topicUserIds, ...ids]);
        }
      } else {
        setTopicUserIds([]);
      }
    }, [topicNameWatched]);

    useEffect(() => {
      if (commentWatched) {
        if (commentWatched.match(regex)) {
          const ids = commentWatched.match(regex) as string[];
          setUserIds([...userIds, ...ids]);
        }
      } else {
        setUserIds([]);
      }
    }, [commentWatched]);

    return (
      <FormContainer canEditOrder={canEditOrder}>
        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <FormLabel>Topic Description</FormLabel>
          <Controller
            control={control}
            name="name"
            rules={topicDescriptionValidation({ required: true })}
            render={({ field: { onChange, ref, value } }) => (
              <MentionsInput
                inputRef={ref}
                onChange={onChange}
                value={value}
                classNames={mentions}
                aria-invalid={errors.name ? 'true' : 'false'}
                placeholder="Enter topic description"
              >
                <Mention
                  data={selectedUsers}
                  className={mentions.mentions__mention}
                  markup="@[__display__]{__id__}"
                />
              </MentionsInput>
            )}
          />
          <FormError
            label="Topic description"
            error={errors.name}
            validationSchema={topicDescriptionValidation({ required: true })}
          />

          <Spacer h="16px" />
          <FormLabel>Select topic type</FormLabel>

          <Controller
            control={control}
            name="topicType"
            render={({ field: { value, ref } }) => (
              <Select
                ref={ref}
                options={topicTypeOptions}
                onChange={(val: SelectOptionProps) =>
                  setValue('topicType', val.value, { shouldDirty: true })
                }
                value={
                  topicTypeOptions?.find((option) => option.value === value) ??
                  null
                }
                placeholder="Select an item"
                isDisabled={topic !== null}
              />
            )}
          />

          <Spacer h="16px" />
          {topicTypeWatched !== TopicType.OTHER && (
            <>
              <FormLabel>Select item (Dependant on upper selection)</FormLabel>

              <Controller
                control={control}
                name="topicItem"
                render={({ field: { value, ref } }) => (
                  <Select
                    ref={ref}
                    options={itemOptions}
                    onChange={(val: SelectOptionProps) =>
                      setValue('topicItem', val.value, { shouldDirty: true })
                    }
                    value={
                      itemOptions?.find((option) => option.value === value) ??
                      null
                    }
                    placeholder={selectTopicItemPlaceholder()}
                    isDisabled={isItemInputDisabled()}
                  />
                )}
              />

              <Spacer h="16px" />
            </>
          )}
          <FormLabel>Comment (Optional)</FormLabel>
          <Wrapper flex column>
            <Controller
              control={control}
              name="comment"
              rules={topicCommentValidation()}
              render={({ field: { onChange, ref, value } }) => (
                <MentionsInput
                  onPaste={handlePaste}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  inputRef={ref}
                  onChange={onChange}
                  value={value}
                  classNames={mentions}
                  aria-invalid={errors.comment ? 'true' : 'false'}
                  placeholder="Enter a comment"
                >
                  <Mention
                    data={selectedUsers}
                    className={mentions.mentions__mention}
                    markup="@[__display__]{__id__}"
                  />
                </MentionsInput>
              )}
            />
            <FormError
              label="Comment"
              error={errors.comment}
              validationSchema={topicCommentValidation()}
            />

            <Spacer h="23px" />
            <Divider />
          </Wrapper>

          <Spacer h="24px" />

          <ActionsContainer flex column between>
            {!!commentFiles?.length && (
              <CommentAttachmentsContainer>
                <ScrollbarsCustom
                  autoHide
                  autoHeight
                  autoHeightMax={120}
                  autoHideTimeout={500}
                  autoHideDuration={300}
                >
                  {commentFiles.map((file, index) => (
                    <CommentAttachment
                      key={`${file.size}--${file.name}`}
                      fileName={file.name}
                      fileType={file.type}
                      deleteAttachment={() => deleteSingleAttachment(index)}
                    />
                  ))}
                </ScrollbarsCustom>
              </CommentAttachmentsContainer>
            )}

            <Wrapper flex justifyEnd>
              <MenuWithDropdown
                position="top right"
                className="position-fixed"
                trigger={
                  <ButtonAttachmentIcon type="button">
                    <AttachmentIcon />
                  </ButtonAttachmentIcon>
                }
              >
                {(close: () => void) => {
                  return (
                    <SelectCommentAttachmentMenu
                      onDropboxFileSelected={onDropboxFileSelectedHandler}
                      closeDropdown={close}
                      handleFileInput={handleFileInput}
                    />
                  );
                }}
              </MenuWithDropdown>
            </Wrapper>

            <Wrapper flex justifyEnd mtAuto>
              <ButtonSecondary
                type="button"
                onClick={() => {
                  dispatch(setCreateTopic(false));
                  dispatch(setUpdatedTopic(null));
                  removeItem();
                }}
                disabled={addingTopic}
              >
                Cancel
              </ButtonSecondary>
              <Spacer w="10px" />
              <ButtonPrimary type="submit" disabled={isSubmitDisabled()}>
                {addingTopic ? 'Saving' : 'Save'}
                <Loader
                  hidden={!addingTopic}
                  insideButton
                  noSpacing
                  size={16}
                />
              </ButtonPrimary>
            </Wrapper>
          </ActionsContainer>
        </FormStyled>
      </FormContainer>
    );
  }
);
