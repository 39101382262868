import { useEffect, useState } from 'react';
import { Location } from 'history';
import { RootState } from 'store';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { getDealership } from 'overview/dealership/store/dealershipActions';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderStatusEnums, OrderTypeEnums } from 'order/enums/orderEnums';
import { deleteOrder, unlockOrderForEdit } from 'order/store/orderActions';

import { Tooltip } from 'shared/components/Tooltip';
import AvatarCircle from 'shared/components/AvatarCircle';

import ElipsisText from 'shared/components/ElipsisText';
import Flash from 'shared/components/Flash';
import { ButtonIcon, ButtonSecondary } from 'shared/components/Button';
import { H3 } from 'shared/components/Typography';
import { OrderFlowsEnum, useIsOrderFlow } from 'shared/hooks/useIsOrderFlow';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { containerWidth } from 'shared/config/Variables';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useGetOrderMetaNames } from 'shared/hooks/useGetOrderMetaNames';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useIsOrderInStatus } from 'shared/hooks/useIsOrderInStatus';
import { useOrderFlow } from 'shared/hooks/useOrderFlow';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import { OrderHeaderTestContainerEnum } from 'tests/enums/OrderHeaderEnums';
import { testId } from 'tests/utils';

import OrderAssignees, {
  AssigneeAvatar,
  AssigneeAvatarWrapper,
  AssigneesList,
} from '../OrderAssignees/OrderAssignees';
import OrderPriority from './OrderPriority/OrderPriority';
import OrderStatus from './OrderStatus/OrderStatus';
import ShipWeek from './ShipWeek/ShipWeek';
import SyncToolFailure from './SyncToolFailure/SyncToolFailure';
import UtilService from '../../../shared/services/util.service';
import { ConfirmationModal } from '../../../shared/components/ConfirmationModal';
import { OrderStatusEditLog } from '../OrderStatusEditLog/OrderStatusEditLog';
import { ServerErrorResponse } from '../../../shared/interface/serverResponses/ServerErrorResponse';

const HeaderWrapper = styled(Wrapper)`
  background-color: ${({ theme }) => theme.white};
  border-bottom: 1px solid ${({ theme }) => theme.alto};
  left: 0;
  min-height: 64px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 6;
`;

const HeaderContainer = styled(Wrapper)`
  flex: 1;
  margin: 0 auto;
  max-width: ${containerWidth}px;
  min-height: 64px;
  padding: 9px 30px;
`;

const HeaderTitleHolder = styled.div`
  margin-right: auto;
`;

const CloseButton = styled(ButtonIcon)`
  path {
    fill: ${({ theme }) => theme.lynch};
  }

  &:hover {
    path {
      fill: ${({ theme }) => theme.lynch};
    }
  }
`;

export const OrderHeader = () => {
  const { orderId } = useParams<OrderPageParams>();
  const editMode = !!orderId;
  const history = useHistory();
  const location: Location<{ backToTab: number; backToQuotesBoard: boolean }> =
    useLocation();

  const isQuoteFlow = useIsOrderFlow(OrderFlowsEnum.QUOTE);

  const queryParams = new URLSearchParams(location.search);

  const dispatch = useAppDispatch();

  const [isDiscardConfirmationModalOpen, setIsDiscardConfirmationModalOpen] =
    useState(false);

  const canCreateAssignee = useHasPermissions(
    userPermissionsValues.CREATE_ASSIGNEE
  );

  const canDeleteOrder = useHasPermissions(userPermissionsValues.ORDER_DELETE);

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const { jobName } = useGetOrderMetaNames();

  const orderLockInfo = useSelector(
    (state: RootState) => state.orderReducer.lockInfo
  );

  const [isAsap, asapPage] = useOrderFlow('asap');

  const [isDuplicate, duplicatePage] = useOrderFlow('duplicate');

  const isAsapOrDuplicate = isAsap || isDuplicate;

  const isOrderSubmitted = useIsOrderInStatus({
    status: OrderStatusEnums['Not Submitted'],
    greaterThen: true,
  });

  const dealership = useSelector(
    (state: RootState) => state.dealershipReducer.dealership
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const isOrderCanceled = useIsOrderInStatus({
    status: OrderStatusEnums.Canceled,
  });

  const isOrderInvoiced = useIsOrderInStatus({
    status: OrderStatusEnums.Invoiced,
  });

  const canViewStatusLog = useHasPermissions(
    userPermissionsValues.ORDER_VIEW_STATUS_LOG
  );

  const closeClickHandler = () => {
    UtilService.onPopupClose();

    history.push(
      `/overview/${location.state?.backToQuotesBoard ? 'quotes' : 'dashboard'}`,
      location.state
    );

    if (orderLockInfo) {
      dispatch(unlockOrderForEdit({ orderId, orderLockId: orderLockInfo.id }));
    }
  };

  const newOrderId = queryParams.get('newOrderId');

  const onSuccessfulOrderDelete = () => {
    toast.success('You have successfully deleted the order.');
  };

  const onFailedOrderDelete = (err: ServerErrorResponse) => {
    toast.error(
      err.status === 403
        ? "You don't have permission to delete this order."
        : 'Order cannot be deleted.'
    );
  };

  const onOrderDeleteHandler = () => {
    dispatch(
      deleteOrder(
        {
          orderId: newOrderId ?? '',
        },
        onSuccessfulOrderDelete,
        onFailedOrderDelete
      )
    );
  };

  const onDiscardModalConfirm = () => {
    if (newOrderId && canDeleteOrder) {
      onOrderDeleteHandler();
    }
    closeClickHandler();
  };

  useEffect(() => {
    if (order?.dealershipId) {
      dispatch(
        getDealership({
          dealershipId: order.dealershipId,
        })
      );
    }
  }, [order]);

  const DiscardConfirmationModal = (
    <ConfirmationModal
      cancel={() => setIsDiscardConfirmationModalOpen(false)}
      confirm={onDiscardModalConfirm}
      opened={isDiscardConfirmationModalOpen}
      message="Are you certain you want to terminate the duplicate process?"
      title="Discard Duplicate Draft"
      buttonText="Discard"
    />
  );

  const handleXButtonClick = () => {
    if (canDeleteOrder) {
      setIsDiscardConfirmationModalOpen(true);
    } else {
      onDiscardModalConfirm();
    }
  };

  return (
    <>
      <HeaderWrapper
        flex
        middle
        className="order-header"
        {...testId(OrderHeaderTestContainerEnum.ORDER_HEADER_WRAPPER)}
      >
        <HeaderContainer flex middle>
          <HeaderTitleHolder>
            <Wrapper flex middle>
              {!editMode && !order && !isAsap && !isDuplicate && (
                <H3>New {isQuoteFlow ? 'Quote Request' : 'Order Draft'}</H3>
              )}

              {isAsap && <H3>New {asapPage.toUpperCase()} Order Draft</H3>}

              {isDuplicate && (
                <H3>New {duplicatePage.toUpperCase()} Order Draft</H3>
              )}

              <SyncToolFailure />

              {!isAsap && !isDuplicate && order?.cancellationRequested && (
                <>
                  <Spacer w="20px" />
                  <Flash
                    type="Order"
                    {...testId(OrderHeaderTestContainerEnum.ORDER_HEADER_FLASH)}
                  />
                  <Spacer w="20px" />
                </>
              )}

              {editMode && order && jobName && !isDuplicate && !isAsap && (
                <Wrapper flex middle>
                  <Wrapper>
                    <H3>
                      <ElipsisText maxWidth={400} position="bottom">
                        {order.type === OrderTypeEnums.Quote
                          ? 'Quote #'
                          : 'Order #'}
                        {order.serialNumber}
                        {order.name
                          ? ` ${
                              isUserCSR
                                ? `  ${
                                    dealership?.name
                                      ? `- ${dealership?.name}`
                                      : ''
                                  }`
                                : ''
                            }`
                          : ''}
                      </ElipsisText>
                    </H3>
                    <H3>
                      <ElipsisText
                        maxWidth={400}
                        position="bottom"
                      >{`${jobName} - ${order.name}`}</ElipsisText>
                    </H3>
                  </Wrapper>
                  <Spacer w="64px" />

                  {isOrderSubmitted && (
                    <OrderStatus
                      {...testId(
                        OrderHeaderTestContainerEnum.ORDER_HEADER_STATUS
                      )}
                    />
                  )}
                </Wrapper>
              )}

              {/* order status edit log */}
              {order &&
                isOrderSubmitted &&
                canViewStatusLog &&
                !isOrderCanceled &&
                !isAsap &&
                !isDuplicate && (
                  <>
                    <OrderStatusEditLog
                      {...testId(
                        OrderHeaderTestContainerEnum.ORDER_HEADER_STATUS_EDIT_LOG
                      )}
                    />
                    <Spacer w="8px" />
                  </>
                )}

              {/* assigne for csr  */}
              {order &&
                editMode &&
                canCreateAssignee &&
                isOrderSubmitted &&
                !isOrderCanceled &&
                !isOrderInvoiced && (
                  <>
                    <OrderAssignees
                      {...testId(
                        OrderHeaderTestContainerEnum.ORDER_HEADER_ASSIGNESS
                      )}
                    />
                  </>
                )}

              {order &&
                order.assigneeResponses &&
                editMode &&
                !canCreateAssignee &&
                isOrderSubmitted &&
                !isOrderCanceled &&
                !isOrderInvoiced && (
                  <AssigneesList flex middle>
                    {order.assigneeResponses!.map((user) => (
                      <AssigneeAvatarWrapper key={user.id} position="relative">
                        <AssigneeAvatar>
                          <AvatarCircle user={user} size={32} />
                        </AssigneeAvatar>

                        <Tooltip position="bottom">
                          {user.firstName} {user.lastName}
                        </Tooltip>
                      </AssigneeAvatarWrapper>
                    ))}
                  </AssigneesList>
                )}
            </Wrapper>
          </HeaderTitleHolder>

          {order && (
            <>
              <OrderPriority order={order} />
              <ShipWeek order={order} />
            </>
          )}

          {isAsapOrDuplicate ? (
            <CloseButton onClick={handleXButtonClick}>
              <CloseIcon />
            </CloseButton>
          ) : (
            <ButtonSecondary onClick={closeClickHandler}>Close</ButtonSecondary>
          )}
        </HeaderContainer>
      </HeaderWrapper>

      {DiscardConfirmationModal}
    </>
  );
};
