import styled from 'styled-components';
import { Location } from 'history';
import { RootState } from 'store';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

import { RenderPageNotFound } from 'routes/RenderPageNotFound';

import { CurtainSidebar } from 'curtain/CurtainSidebar';
import {
  clearCurtain,
  getNumberOfUnreadTopics,
  setCurtainTab,
} from 'curtain/store/curtainActions';

import OrderAsapOrDuplicate from 'order/wizard/orderASAP/OrderAsapOrDuplicate';
import OrderAttachments from 'order/wizard/orderAttachments/OrderAttachments';
import OrderEditLock from 'order/components/OrderEditLock/OrderEditLock';
import OrderForm from 'order/wizard/orderForm/OrderForm';
import OrderLineItems from 'order/wizard/orderLineItems/OrderLineItems';
import OrderLocked from 'order/components/OrderLocked/OrderLocked';
import OrderOverview from 'order/wizard/orderOverview/OrderOverview';
import OrderReviewPricing from 'order/wizard/orderReviewPricing/OrderReviewPricing';
import OrderReviewPricingSalesMaterial from 'order/wizard/orderReviewPricing/OrderReviewPricingSalesMaterial';
import OrderStyles from 'order/wizard/orderStyles/OrderStyles';
import OrderStylesAdjustments from 'order/wizard/orderStyles/productLines/steps/OrderStylesAdjustments/OrderStylesAdjustments';
import OrderTabs from 'order/components/OrderTabs/OrderTabs';
import UpchargeDifferencesModal from 'order/components/UpchargeDifferencesModal/UpchargeDifferencesModal';
import { Acknowledgment } from 'order/wizard/orderAcknowledgement/Acknowledgment';
import { OrderHeader } from 'order/components/OrderHeader/OrderHeader';
import { OrderLockInfo } from 'order/interfaces/Order';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';
import { clearLineItemsReducer } from 'order/wizard/orderLineItems/store/orderLineItemsActions';
import { clearStyles } from 'order/wizard/orderStyles/productLines/store/orderStylesActions';
import { clearTopicReducer } from 'order/wizard/topic/store/TopicActions';
import { getOrderAssignees } from 'order/components/OrderAssignees/store/orderAssigneesActions';
import { SyncToolPopUp } from 'shared/components/SyncToolPopUp';

import { setDealershipSalesRep } from 'overview/dealership/store/dealershipActions';

import {
  clearOrder,
  getLockInfoOnOrder,
  getOrder,
  getOrderStyles,
  setCanEditOrder,
  setShouldNavigateToTopic,
} from 'order/store/orderActions';

import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import MetaTitle from 'shared/components/MetaTitle';
import { Container } from 'shared/components/Container';
import { Page } from 'shared/components/Page';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { getProductLines } from 'shared/store/sharedActions';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useApplyNotifLocationState } from 'shared/hooks/useApplyNotifLocationState';
import { useCanEditOrder } from 'shared/hooks/useCanEditOrder';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useOrderFlow } from 'shared/hooks/useOrderFlow';
import { useOrderProductType } from 'shared/hooks/useOrderProductType';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import {
  ISkeletonGridChild,
  SkeletonChild,
} from 'shared/components/SkeletonGrid';

import { testId } from 'tests/utils';
import { OrderHeaderTestContainerEnum } from 'tests/enums/OrderHeaderEnums';

import { getUnresolvedWorkablesCount } from 'curtain/tabs/CurtainWorkablesTab/store/curtainWorkablesActions';
import { CurtainTabsEnum } from '../../curtain/enums/CurtainTabsEnum';
import { TopicType } from '../../curtain/enums/TopicTypeEnum';

const OrderContainer = styled(Wrapper)<{ canEditOrder: boolean }>`
  padding: ${({ canEditOrder }) => (canEditOrder ? 104 : 60)}px 80px 330px;
  max-width: 1290px;
  margin: 0 auto;
`;

const OrderPageStyled = styled(Page)`
  padding-top: 64px;
`;

const FixedContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.mischka};
  position: fixed;
  left: 0;
  right: 0;
  top: 65px;
  z-index: 5;
  background-color: ${({ theme }) => theme.white};
  padding: 16px 0;
`;

export const OrderPage = memo(() => {
  useApplyNotifLocationState();

  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(useLocation().search);

  // edit mode
  const { orderId } = useParams<OrderPageParams>();
  const editMode = !!orderId;

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const orderIsLocked = order?.isLocked ?? false;

  const shouldNavigateToTopic = useSelector(
    (state: RootState) => state.orderReducer.shouldNavigateToTopic
  );

  const [isAsap] = useOrderFlow('asap');

  const [isDuplicate] = useOrderFlow('duplicate');

  const [orderLoading, setOrderLoading] = useState(false);
  const [orderSubmitting, setOrderSubmitting] = useState(false);

  const isAsapOrDuplicate = isAsap || isDuplicate;

  const loggedInUserId = useSelector(
    (state: RootState) => state.authReducer.user?.id
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const canViewAssignees = useHasPermissions(
    userPermissionsValues.READ_ASSIGNEE
  );

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const isOrderSalesMaterial = useOrderProductType(
    OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const canEditOrder = useCanEditOrder();

  const onGetOrderErrorHandler = (error: ServerErrorResponse) => {
    if (error.status === 403) {
      history.push('/forbidden');
    }

    if (error.status === 404) {
      history.push('/page-not-found');
    }
  };

  const onGetLockInfoSuccessHandler = (lockInfo: OrderLockInfo) => {
    const meAsEditor = lockInfo?.lockedByUserId === loggedInUserId;
    dispatch(setCanEditOrder(meAsEditor));
  };

  const onGetLockInfoErrorHandler = () => {
    dispatch(setCanEditOrder(false));
  };

  const getReviewPricingPageBasedOnOrderType = () => {
    if (order && isOrderSalesMaterial) {
      return OrderReviewPricingSalesMaterial;
    }

    if (order && !isOrderSalesMaterial) {
      return OrderReviewPricing;
    }

    return Loader;
  };

  const topicIdFromUrl = queryParams.get('topicId');
  const topicTypeFromUrl = queryParams.get('topicType');

  const navigateToTab = () => {
    switch (topicTypeFromUrl) {
      case TopicType.STYLE:
        return 'styles';
      default:
        return 'line-items';
    }
  };

  const generateSkeletonGridItem = (w: number) => {
    return {
      w,
      h: 16,
      spaceBottom: 0,
      borderRadius: 5,
    } as ISkeletonGridChild;
  };

  useEffect(() => {
    if (topicIdFromUrl && shouldNavigateToTopic === null) {
      history.push({
        pathname: `/order/${orderId}/${navigateToTab()}`,
        search: queryParams.toString(),
      });
      dispatch(setCurtainTab(CurtainTabsEnum.COLLABORATION_TAB));
      dispatch(setShouldNavigateToTopic(true));
    }
  }, [topicIdFromUrl, shouldNavigateToTopic]);

  const clearScrollPositions = () => {
    const pages = [
      'overview',
      'styles',
      'line-items',
      'attachments',
      'order-overview',
      'review-pricing',
      'acknowledgment',
    ];

    let n = window.sessionStorage.length;
    // eslint-disable-next-line no-plusplus
    while (n--) {
      const key = window.sessionStorage.key(n);
      if (key !== null) {
        if (
          pages.some((p) => key.includes(p)) ||
          key.includes('draft') ||
          key.includes('newtopic')
        ) {
          window.sessionStorage.removeItem(key!);
        }
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getProductLines());

    if (editMode) {
      setOrderLoading(true);
      dispatch(getOrder({ orderId }, setOrderLoading, onGetOrderErrorHandler));
      dispatch(getNumberOfUnreadTopics(orderId));
      dispatch(getUnresolvedWorkablesCount({ orderId }));
    } else {
      dispatch(setCanEditOrder(true));
    }

    const unsubscribe = history.listen((locationState) => {
      const loc = locationState as unknown as Location<{
        dontClearCurtain: boolean;
      }>;

      if (!loc.state?.dontClearCurtain) {
        dispatch(clearCurtain());
      }
    });

    return () => {
      dispatch(clearOrder());
      dispatch(clearCurtain());
      dispatch(clearStyles());
      dispatch(clearLineItemsReducer());
      dispatch(clearTopicReducer());
      clearScrollPositions();
      unsubscribe();
    };
  }, [editMode]);

  // get order assigness
  useEffect(() => {
    if (editMode && canViewAssignees) {
      dispatch(getOrderAssignees({ orderId }));
    }
  }, [editMode, canViewAssignees]);

  useEffect(() => {
    // dont check active when duplicating or asaping the order.
    if (
      isAsapOrDuplicate !== null &&
      !isAsapOrDuplicate &&
      orderId &&
      loggedInUserId
    ) {
      dispatch(
        getLockInfoOnOrder(
          { orderId },
          onGetLockInfoSuccessHandler,
          onGetLockInfoErrorHandler
        )
      );
    }
  }, [isAsapOrDuplicate, loggedInUserId]);

  useEffect(() => {
    return () => {
      if (isUserCSR) {
        dispatch(setDealershipSalesRep(null));
      }
    };
  }, [isUserCSR]);

  useEffect(() => {
    dispatch(getOrderStyles(orderId));
  }, [orderId]);

  return (
    <OrderPageStyled className="order-page">
      <OrderHeader />

      <MetaTitle
        title={
          editMode
            ? `Order - ${order?.name ?? '...'} | Plato Connect`
            : 'New order draft | Plato Connect'
        }
      />

      <OrderContainer
        className="order-page-container"
        canEditOrder={canEditOrder}
      >
        {editMode && !orderLoading && !(isAsap && isDuplicate) && <OrderTabs />}

        {editMode && orderLoading && (
          <FixedContainer>
            <Container>
              <Wrapper flex gap={20}>
                <Spacer w="0px" />
                <SkeletonChild
                  {...generateSkeletonGridItem(80)}
                  borderRadius={16}
                />
                <SkeletonChild
                  {...generateSkeletonGridItem(70)}
                  borderRadius={16}
                />
                <SkeletonChild
                  {...generateSkeletonGridItem(100)}
                  borderRadius={16}
                />
                <SkeletonChild
                  {...generateSkeletonGridItem(90)}
                  borderRadius={16}
                />
                <SkeletonChild
                  {...generateSkeletonGridItem(120)}
                  borderRadius={16}
                />
                <SkeletonChild
                  {...generateSkeletonGridItem(130)}
                  borderRadius={16}
                />
              </Wrapper>
            </Container>
          </FixedContainer>
        )}

        <Spacer h="30px" />

        <Switch location={location}>
          <Route
            path={['/order/asap', '/order/duplicate']}
            component={OrderAsapOrDuplicate}
          />

          <Route
            path={['/order/new', '/quote/new', '/order/:orderId/overview']}
            render={(props) => (
              <OrderForm
                {...props}
                isOrderFormSubmitting={(isSubmitting) =>
                  setOrderSubmitting(isSubmitting)
                }
              />
            )}
          />

          <Redirect exact from="/order" to="/order/new" />

          <Route path="/order/:orderId/styles" component={OrderStyles} />

          <Route
            path="/order/:orderId/adjustments"
            component={OrderStylesAdjustments}
          />

          <Route path="/order/:orderId/line-items" component={OrderLineItems} />

          <Route
            path="/order/:orderId/attachments"
            component={OrderAttachments}
          />

          <Route
            path="/order/:orderId/order-overview"
            component={OrderOverview}
          />

          <Route
            path="/order/:orderId/review-pricing"
            component={getReviewPricingPageBasedOnOrderType()}
          />

          <Route
            path="/order/:orderId/acknowledgment"
            component={Acknowledgment}
          />
          <RenderPageNotFound />
        </Switch>
      </OrderContainer>

      {/* order edit lock */}
      {canEditOrder && (
        <OrderEditLock
          {...testId(OrderHeaderTestContainerEnum.ORDER_HEADER_EDIT_MODE)}
        />
      )}

      {isUserDealerOrSalesRep && orderIsLocked && (
        <>
          <OrderLocked />
          <Spacer w="8px" />
        </>
      )}

      <UpchargeDifferencesModal />

      {editMode && <CurtainSidebar />}

      {editMode && (orderLoading || orderSubmitting) && (
        <LoaderFullScreen
          position="fixed"
          top={64}
          bottom={72}
          flex
          middle
          center
        >
          <Loader platoLogo={1} size={100} />
        </LoaderFullScreen>
      )}
      <SyncToolPopUp />
    </OrderPageStyled>
  );
});
