import { AnyAction } from 'redux';

import { FormAndCatalog } from 'overview/interface/FormAndCatalog';
import { IShipWeek } from 'overview/interface/IShipWeek';

import { Dealership } from 'shared/interface/Dealership';
import { INotification } from 'overview/interface/Notification';
import { OverviewActions, FormsAndCatalogsPayload } from './overviewActions';

interface OverviewReducerState {
  catalogs: FormAndCatalog[] | null;
  forms: FormAndCatalog[] | null;
  howToCatalog: FormAndCatalog[] | null;
  shipWeeks: IShipWeek[] | null;
  dealerships: Dealership[] | null;
  activateDealershipResponse: INotification | null;
}

const initialState: OverviewReducerState = {
  catalogs: null,
  forms: null,
  howToCatalog: null,
  shipWeeks: null,
  dealerships: null,
  activateDealershipResponse: null,
};

export const overviewReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case OverviewActions.SET_FORMS_AND_CATALOGS: {
      const { catalogs, forms, howToCatalog } =
        action.payload as FormsAndCatalogsPayload;

      return {
        ...state,
        catalogs,
        forms,
        howToCatalog,
      };
    }
    case OverviewActions.SET_SHIP_WEEKS: {
      return {
        ...state,
        shipWeeks: action.payload as IShipWeek[],
      };
    }

    case OverviewActions.SET_ALL_DEALERSHIPS: {
      return {
        ...state,
        dealerships: action.payload as Dealership[],
      };
    }

    case OverviewActions.SET_ACTIVATE_DEALERSHIP_RESPONSE: {
      return {
        ...state,
        activateDealershipResponse: action.payload as INotification,
      };
    }

    default:
      return state;
  }
};
