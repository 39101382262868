import { useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { ACKStatusEnum } from 'order/wizard/orderAcknowledgement/enums/ACKStatusEnum';
import { changesRequestFinsih } from 'order/wizard/orderAcknowledgement/store/ACKActions';
import { Order } from 'order/interfaces/Order';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import { TopicType } from 'curtain/enums/TopicTypeEnum';

import {
  clearCurtain,
  setActiveChannel,
  setActiveLineItemID,
  setActiveStyleID,
  setBackToChanel,
  setCreateTopic,
  setTopicItemDefaultValue,
  setTopicTypeDefaultValue,
} from 'curtain/store/curtainActions';
import { Tooltip } from 'shared/components/Tooltip';

import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import Loader from 'shared/components/Loader';
import { testId } from 'tests/utils';
import { ACKTestButtonEnum } from 'tests/enums/ACKTestEnums';
import { Divider } from '@material-ui/core';
import { P } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import SVG from 'shared/components/SVG';

import { silverChaliceTwo } from 'shared/config/Colors';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow_downward.svg';

const ActionsContainer = styled.div`
  bottom: 0;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  position: absolute;
  right: 0;
  width: 100%;
  flex-direction: column;
`;

const Container = styled(Wrapper)`
  padding: 16px 0px;
  align-items: center;
  margin-right: -21px;
`;

const CollaborationActions = ({
  isScrolledToEnd,
}: {
  isScrolledToEnd?: boolean;
}) => {
  const { orderId } = useParams<OrderPageParams>();

  const dispatch = useAppDispatch();

  const { acknowledgementId, acknowledgementStatus } = useSelector(
    (state: RootState) => state.orderReducer.order ?? ({} as Order)
  );

  const activeEntityID = useSelector(
    (state: RootState) => state.curtainReducer.activeEntityID
  );

  const activeEntityType = useSelector(
    (state: RootState) => state.curtainReducer.activeEntityType
  );

  const activeEntityName = useSelector(
    (state: RootState) => state.curtainReducer.activeEntityName
  );

  const activeChannel = useSelector(
    (state: RootState) => state.curtainReducer.activeChannel
  );

  const [requestChangeFinish, setRequestChangeFinish] = useState(false);

  const onCollaborationCancelClickHandler = () => {
    if (activeChannel) {
      dispatch(setActiveChannel(null));
      dispatch(setActiveStyleID(null));
      dispatch(setActiveLineItemID(null));
      dispatch(setBackToChanel(null));
    }

    if (!activeChannel) {
      dispatch(clearCurtain());
    }
  };

  const onCollaborationCreateNewClickHandler = () => {
    dispatch(setCreateTopic(true));

    if (activeEntityType === TopicType.STYLE) {
      dispatch(
        setTopicTypeDefaultValue({
          value: TopicType.STYLE,
          label: 'Style',
        })
      );
    }

    if (activeEntityType === TopicType.LINE_ITEM) {
      dispatch(
        setTopicTypeDefaultValue({
          value: TopicType.LINE_ITEM,
          label: 'Line Item',
        })
      );
    }

    if ((activeEntityID && activeEntityName) || activeChannel) {
      dispatch(
        setTopicItemDefaultValue({
          value: activeEntityID ?? activeChannel?.entityId,
          label: activeEntityName ?? activeChannel?.name,
        })
      );
    }
  };

  const onCollaborationFinishRequestClickHandler = (close: () => void) => {
    setRequestChangeFinish(true);

    if (orderId && acknowledgementStatus) {
      dispatch(
        changesRequestFinsih(
          {
            orderId,
            acknowledgementId: acknowledgementId as string,
          },
          () => {
            close();
            dispatch(clearCurtain());
          },
          setRequestChangeFinish
        )
      );
    }
  };

  return (
    <ActionsContainer>
      {!isScrolledToEnd && (
        <>
          <Wrapper width={95} style={{ marginRight: '-22px' }}>
            <Divider />
          </Wrapper>
          <Container flex center width={95}>
            <Wrapper flex withTooltip gap={12}>
              <P fontSize={12} color={silverChaliceTwo}>
                Scroll for more
              </P>
              <SVG
                animateTransform={['transform', 'borderRadius']}
                icon={<ArrowDownIcon />}
              />
              <Tooltip position="left">
                Scroll to see more collaborations.
              </Tooltip>
            </Wrapper>
          </Container>
          <Wrapper width={86} style={{ paddingBottom: '16px' }}>
            <Divider />
          </Wrapper>
        </>
      )}

      {(acknowledgementStatus ?? 0) !== ACKStatusEnum.CHANGES_REQUESTED && (
        <Wrapper flex>
          <ButtonSecondary onClick={onCollaborationCancelClickHandler}>
            Cancel
          </ButtonSecondary>

          <Spacer w="10px" />

          <ButtonPrimary onClick={onCollaborationCreateNewClickHandler}>
            Create New
          </ButtonPrimary>
        </Wrapper>
      )}

      {(acknowledgementStatus ?? 0) === ACKStatusEnum.CHANGES_REQUESTED && (
        <Wrapper flex>
          <ConfirmationModal
            trigger={
              <ButtonPrimary
                disabled={requestChangeFinish}
                {...testId(ACKTestButtonEnum.FINISH_REQUEST)}
              >
                Finish Request
                <Loader
                  insideButton
                  hidden={!requestChangeFinish}
                  size={16}
                  noSpacing
                />
              </ButtonPrimary>
            }
            title="Finish Requesting Changes"
            message="If done with requesting changes, please proceed so the responsible person for this order can take actions."
            buttonText="Finish"
            confirm={(close) => onCollaborationFinishRequestClickHandler(close)}
          />
          <Spacer w="10px" />
          <ButtonSecondary onClick={onCollaborationCreateNewClickHandler}>
            Create New
          </ButtonSecondary>
        </Wrapper>
      )}
    </ActionsContainer>
  );
};

CollaborationActions.defaultProps = {
  isScrolledToEnd: false,
};

export default CollaborationActions;
