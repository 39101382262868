import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import OrderLogs from 'order/components/OrderLogs/OrderLogs';
// import { OrderStatusEnums } from 'order/enums/orderEnums';
import { useOrderFlow } from 'shared/hooks/useOrderFlow';

import {
  getCollaborationAttachments,
  getOrderAttachments,
  saveOrderAttachments,
  setOrderAttachments,
} from 'order/wizard/orderAttachments/store/orderAttachmentsActions';

import { AttachmentBox } from 'shared/components/AttachmentBox';
import { deleteFile } from 'shared/store/sharedActions';
import { H2, H4, H5, PDark, PMedium } from 'shared/components/Typography';
import { IAttachment } from 'shared/interface/IAttachment';
import { IFile } from 'shared/interface/IFile';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { setSelectedAttachments } from 'shared/components/AttachmentsModal/store/attachmentsModalActions';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import AttachmentsModal from 'shared/components/AttachmentsModal/AttachmentModal';
import DragAndDropFiles from 'shared/components/DragAndDropFiles';
import Loader from 'shared/components/Loader';
import UtilService from 'shared/services/util.service';

import {
  AttachmentModalSectionEnums,
  generateAttachmentSections,
} from 'shared/components/AttachmentsModal/enums/AttachmentModalSectionEnums';

import {
  globalMaxDWGFileSize,
  globalMaxFileSize,
} from 'shared/config/Variables';
import { useIsOrderInStatus } from 'shared/hooks/useIsOrderInStatus';
import { OrderStatusEnums } from 'order/enums/orderEnums';
import useWindowScrollPosition from 'shared/hooks/useWindowScrollPosition';
import { TabbableButton } from 'shared/components/Button';
import { lynch } from 'shared/config/Colors';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';
import { OrderAsapParams } from '../orderASAP/interface/OrderAsapParams';
import EmptyState from '../../../shared/components/EmptyState';
import { ReactComponent as PlatoLogoIcon } from '../../../assets/icons/plato-logo.svg';
import { Checkbox } from '../../../shared/components/Checkbox';
import { FloatingCheckbox } from '../../../shared/components/FloatingCheckbox';
import {
  addCheckedAttachment,
  removeCheckedAttachment,
} from '../orderASAP/store/OrderAsapActions';

interface SectionWithAttachments {
  attachments: IAttachment[];
  sectionValue: AttachmentModalSectionEnums;
}

const ContainerLeft = styled.div`
  max-width: 840px;
  width: calc(100% - 288px);
  flex: 0 0 100% - 288px;
`;

const ContainerAttachments = styled.div`
  width: calc(100% - 288px);
  flex: 0 0 100% - 288px;
`;

const ContainerRight = styled.div`
  width: 264px;
`;

const BulletPrefix = styled.div`
  position: relative;
  padding-left: 20px;
  :before {
    position: absolute;
    content: '';
    width: 4px;
    height: 4px;
    top: 8px;
    left: 8px;
    background: ${({ theme }) => theme.nevada};
    border-radius: 50%;
  }
`;

const OrderAttachmentContainer = styled.div`
  border-radius: 8px;
  border: 1px solid transparent;
  position: relative;
  transition: border-color 300ms ease;
`;

interface OrderAttachmentsProps {
  fromStepper?: boolean;
}

const OrderAttachments = (props: OrderAttachmentsProps) => {
  const { fromStepper } = props;

  const [attachmentModalOpened, setAttachmentModalOpened] = useState(false);

  const dispatch = useAppDispatch();

  const { orderId } = useParams<OrderPageParams>();

  const [queryFields] = useQueryParams<OrderAsapParams>(['orderId']);

  const [attachmentsLoading, setAttachmentsLoading] = useState(false);

  const attachmentsForCheckedLineItems = useSelector(
    (state: RootState) => state.orderReducer.attachmentsForCheckedLineItems
  );

  const orderAttachments = useSelector(
    (state: RootState) => state.orderReducer.attachments
  );

  const collaborationAttachments = useSelector(
    (state: RootState) => state.orderReducer.collaborationAttachments
  );

  const attachments = fromStepper
    ? attachmentsForCheckedLineItems
    : orderAttachments;

  const checkedAttachments = useSelector(
    (state: RootState) => state.orderAsapReducer.checkedAttachments
  );

  const isCurrentUserViewer = useSelector(
    (state: RootState) => state.orderReducer.isCurrentUserViewer
  );

  const isOrderSubmitted = useIsOrderInStatus({
    status: OrderStatusEnums['Not Submitted'],
    greaterThen: true,
  });

  const internalDocsReadPermission = useHasPermissions(
    userPermissionsValues.INTERNAL_DOCS_READ
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const [isAsap] = useOrderFlow('asap');

  const [isDuplicate] = useOrderFlow('duplicate');

  const isAsapOrDuplicate = isAsap || isDuplicate;

  const failedToReceiveOrderAttachments = () => {
    toast.error('Could not retrieve order attachments.');
    setAttachmentsLoading(false);
  };

  let attachmentsOrderId = orderId;
  if (fromStepper && queryFields?.orderId) {
    attachmentsOrderId = queryFields.orderId;
  }

  useWindowScrollPosition(attachments !== null);

  useEffect(() => {
    /* Call saga to get attachments */
    window.scrollTo(0, 0);
    if (attachmentsOrderId) {
      dispatch(setOrderAttachments(null));
      setAttachmentsLoading(true);
      dispatch(
        getOrderAttachments(
          attachmentsOrderId,
          setAttachmentsLoading,
          failedToReceiveOrderAttachments
        )
      );
      dispatch(
        getCollaborationAttachments(
          attachmentsOrderId,
          () => false,
          failedToReceiveOrderAttachments
        )
      );
    }
  }, [attachmentsOrderId]);

  const showSection = (sectionValue: string) => {
    if (
      sectionValue ===
      AttachmentModalSectionEnums['Plato internal Docs.'].toString()
    ) {
      return internalDocsReadPermission;
    }
    return true;
  };

  const mapSectionValueToLabel = (sectionValue: string) => {
    const foundSection = generateAttachmentSections().find(
      (section) => section.value === sectionValue
    );

    if (foundSection) {
      return foundSection.label;
    }

    return 'Unknown section';
  };

  // if saving attachments to order is success, just update the list of the attachments
  const onSaveOrderAttachmentsSuccessHandler = () => {
    dispatch(
      getOrderAttachments(
        orderId,
        setAttachmentsLoading,
        failedToReceiveOrderAttachments
      )
    );
  };

  // if saving attachments fail; delete recently uploaded files from S3 to clean them up.
  const onSaveOrderAttachmentsFailedHandler = (attcs: IAttachment[]) => {
    attcs.forEach((att) => {
      dispatch(deleteFile(att.fileId!));
    });
  };

  const onModalCloseHandler = (
    files: IFile[],
    selectedAttachments: IAttachment[]
  ) => {
    const attcs = selectedAttachments.map(
      (selectedAtt, index) =>
        ({
          ...selectedAtt,
          fileId: files[index].id,
        } as IAttachment)
    ) as IAttachment[];

    dispatch(
      saveOrderAttachments(
        { attachments: attcs, orderId },
        onSaveOrderAttachmentsSuccessHandler,
        onSaveOrderAttachmentsFailedHandler
      )
    );
  };

  const onSelectedFilesHandler = (files: File[] | null) => {
    if (files) {
      const hasFiles = !!files.length;
      setAttachmentModalOpened(hasFiles);

      if (hasFiles) {
        dispatch(
          setSelectedAttachments(UtilService.mapFilesToIAttachment(files))
        );
      }
    }
  };

  const onAttachmentCheckedHandler = (
    checked: boolean,
    attachment: IAttachment
  ) => {
    if (checked) {
      dispatch(addCheckedAttachment(attachment));
    } else if (attachment.id) {
      dispatch(removeCheckedAttachment(attachment.id));
    }
  };

  const openCollaborationAttachments = () => {
    if (collaborationAttachments != null) {
      collaborationAttachments.forEach(async (att) => {
        await UtilService.openBlob(att.url!, att.extension!);
      });
    }
  };

  const openAllAttachments = (section: AttachmentModalSectionEnums) => {
    if (attachments != null) {
      attachments
        .filter((att) => att.section === section)
        .forEach(async (att) => {
          await UtilService.openBlob(att.url!, att.extension!);
        });
    }
  };
  const renderSections = () => {
    const sectionsWithAttachments: SectionWithAttachments[] = [];
    /* For every unique section from list of attached files, create that section object and store in an array. */
    if (attachments != null) {
      attachments.forEach((attachment) => {
        if (sectionsWithAttachments.length > 0) {
          if (
            !sectionsWithAttachments.some(
              (sectionObject) =>
                sectionObject.sectionValue === attachment.section
            )
          ) {
            sectionsWithAttachments.push({
              sectionValue: attachment.section!,
              attachments: [],
            });
          }
        } else {
          sectionsWithAttachments.push({
            sectionValue: attachment.section!,
            attachments: [],
          });
        }
      });

      /* Iterate through attached files and push every file to a new array for every section object. */
      attachments.forEach((attachment) => {
        sectionsWithAttachments.forEach((section) => {
          if (section.sectionValue === attachment.section) {
            section.attachments.push(attachment);
          }
        });
      });

      const allAttachments = collaborationAttachments?.length
        ? [
            sectionsWithAttachments,
            {
              sectionValue:
                AttachmentModalSectionEnums['Collaboration Attachments'],
              attachments: collaborationAttachments.map((item) => {
                return {
                  ...item,
                  section:
                    AttachmentModalSectionEnums['Collaboration Attachments'],
                };
              }),
            },
          ].flat()
        : sectionsWithAttachments;

      return (
        <>
          {allAttachments
            .filter((section) => {
              const isSectionBlueSlips =
                section.sectionValue ===
                AttachmentModalSectionEnums['Blue Slips'];

              return !isSectionBlueSlips || (isSectionBlueSlips && isUserCSR);
            })
            .sort(
              (sectionA, sectionB) =>
                sectionA.sectionValue - sectionB.sectionValue
            )
            .map((section) => {
              const isLineItemSection =
                section.sectionValue ===
                AttachmentModalSectionEnums['Line Items'];

              const sortLineItems = isLineItemSection
                ? (a: IAttachment, b: IAttachment) => {
                    return UtilService.sortBy(
                      a.lineItem?.number ??
                        section.attachments.filter((x) => !x.lineItem).length,
                      b.lineItem?.number ??
                        section.attachments.filter((x) => !x.lineItem).length,
                      true
                    );
                  }
                : undefined;

              return (
                showSection(section.sectionValue.toString()) && (
                  <div key={`attachmentBox-section-${section.sectionValue}`}>
                    <Wrapper flex>
                      <H4>
                        {mapSectionValueToLabel(
                          section.sectionValue.toString()
                        )}
                      </H4>
                      <Spacer w="24px" />

                      <TabbableButton
                        color={lynch}
                        type="button"
                        onClick={() =>
                          section.sectionValue ===
                          AttachmentModalSectionEnums[
                            'Collaboration Attachments'
                          ]
                            ? openCollaborationAttachments()
                            : openAllAttachments(section.sectionValue)
                        }
                      >
                        Open All
                      </TabbableButton>
                    </Wrapper>

                    <Spacer h="24px" />

                    <OrderAttachmentContainer>
                      {section.attachments
                        .sort(sortLineItems)
                        .map((attachment) => (
                          <Fragment
                            key={`attachmentBox-file-${attachment.name}-${attachment.id}`}
                          >
                            {fromStepper && (
                              <FloatingCheckbox top={40} relative>
                                <Checkbox
                                  id={attachment.id}
                                  checked={
                                    !checkedAttachments
                                      ? false
                                      : checkedAttachments?.some(
                                          (checkedAttachment) =>
                                            checkedAttachment.id ===
                                            attachment.id
                                        )
                                  }
                                  onChange={(e) =>
                                    onAttachmentCheckedHandler(
                                      e.target.checked,
                                      attachment
                                    )
                                  }
                                />
                              </FloatingCheckbox>
                            )}

                            <AttachmentBox
                              attachment={attachment}
                              disableActions={
                                fromStepper ||
                                attachment.section ===
                                  AttachmentModalSectionEnums[
                                    'Collaboration Attachments'
                                  ]
                              }
                            />
                          </Fragment>
                        ))}
                    </OrderAttachmentContainer>
                    <Spacer h="32px" key={`spacer-32px-${section}`} />
                  </div>
                )
              );
            })}
        </>
      );
    }
    return <></>;
  };

  const ContentLeft = (
    <>
      <H2>Upload files</H2>

      <Spacer h="12px" />

      <PDark>
        Add documents, .dwg files or other sort of documents to your attachment
        list.
      </PDark>

      <Spacer h="24px" />

      <DragAndDropFiles
        disabled={isOrderSubmitted && isUserCSR ? false : isCurrentUserViewer}
        selectedFiles={onSelectedFilesHandler}
      />

      <ModalWrapper
        modal
        nested
        closeOnDocumentClick={false}
        open={attachmentModalOpened}
        closeOnEscape={false}
      >
        {(close: () => void) => (
          <Modal close={close} noHeader withoutFooter={1}>
            <AttachmentsModal
              closeModal={close}
              onModalClose={onModalCloseHandler}
            />
          </Modal>
        )}
      </ModalWrapper>

      <Spacer h="72px" />
    </>
  );
  const ContentAttachments = (
    <>
      {attachmentsLoading && <Loader noSpacing size={50} />}
      {fromStepper && !attachments?.length && (
        <EmptyState
          title="No Attachments"
          icon={<PlatoLogoIcon />}
          iconSize={80}
          message="There are no attachments in this order."
        />
      )}
      {!attachmentsLoading && renderSections()}
    </>
  );

  return (
    <>
      <Wrapper flex>
        {!fromStepper ? (
          <ContainerLeft>
            {ContentLeft}

            {ContentAttachments}
          </ContainerLeft>
        ) : (
          <ContainerAttachments>{ContentAttachments}</ContainerAttachments>
        )}

        {!fromStepper && (
          <>
            <Spacer w="24px" />

            <ContainerRight>
              <H5>Attachment standards</H5>

              <Spacer h="16px" />

              <BulletPrefix>
                <PMedium>
                  PDF, JPEG, HEIC (image files for Apple users), Word, Excel,
                  dwg docs, KIT files (used for 2020), PDQ files.
                </PMedium>
              </BulletPrefix>

              <Spacer h="16px" />

              <BulletPrefix>
                <PMedium>
                  Max file size is {globalMaxFileSize} MB for documents and{' '}
                  {globalMaxDWGFileSize} MB for drawing and visuals.
                </PMedium>
              </BulletPrefix>

              <Spacer h="48px" />

              <H5>Relevant info</H5>

              <Spacer h="16px" />

              <BulletPrefix>
                <PMedium>
                  If you have special lighting specs, special specs of any sort
                  add it to the Misc. Documents section.
                </PMedium>
              </BulletPrefix>

              <Spacer h="16px" />

              <BulletPrefix>
                <PMedium>
                  Plato internal docs. are for private documentation available
                  only to Plato personnel
                </PMedium>
              </BulletPrefix>
            </ContainerRight>
          </>
        )}

        {!isAsapOrDuplicate && <OrderLogs bottomPosition />}
      </Wrapper>
    </>
  );
};

OrderAttachments.defaultProps = {
  fromStepper: false,
};

export default OrderAttachments;
