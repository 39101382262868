import { CurtainHeader } from 'curtain/components/CurtainHeader';
import { H3, P, PSmall } from 'shared/components/Typography';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { clearCurtain, setActiveStyleID } from 'curtain/store/curtainActions';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { Style } from 'order/wizard/orderStyles/interface/Style';
import { Wrapper } from 'shared/components/Wrapper';
import { Select } from 'shared/components/Select';
import { Divider, Spacer } from 'shared/components/Layout';
import { FieldName } from 'order/shared/ackchangelog/interface/AckChangeLogItem';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';

import { getOrderStyle } from 'order/store/orderActions';
import { useParams } from 'react-router';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import { CabinetBoxMaterialOptionsEnum } from 'order/wizard/orderStyles/enums/CabinetBoxMaterialOptionsEnum';
import OrderFinishLabel from 'order/components/OrderFinishLabel/OrderFinishLabel';
import OrderFinishValue from 'order/components/OrderFinishValue/OrderFinishValue';

import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { silverChaliceTwo, wildSandTwo } from 'shared/config/Colors';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';

import {
  CloseButton,
  Container,
  StyleDetailsField,
} from './CurtainStyleDetails.style';

export const CurtainStyleDetails = () => {
  const dispatch = useAppDispatch();

  const activeStyleID = useSelector(
    (state: RootState) => state.curtainReducer.activeStyleID
  );

  const orderStyles = useSelector(
    (state: RootState) => state.orderReducer.styles
  );
  const { orderId } = useParams<OrderPageParams>();

  const onClear = () => {
    dispatch(setActiveStyleID(null));
    dispatch(clearCurtain());
  };

  const [selectedStyle, setSelectedStyle] = useState<Style | null>();
  const [selectedStyleId, setSelectedStyleId] = useState<string>();
  const [isStyleDetailsLoading, setIsStyleDetailsLoading] = useState(false);

  const config = useProductLineConfiguration(selectedStyle?.productLine);

  useEffect(() => {
    if (activeStyleID) {
      setSelectedStyleId(activeStyleID);
    }
  }, [orderStyles, activeStyleID]);

  const onSuccessGetOrderStyle = (orderStyle: Style) => {
    setIsStyleDetailsLoading(false);
    setSelectedStyle(orderStyle);
  };

  useEffect(() => {
    if (selectedStyleId) {
      setIsStyleDetailsLoading(true);
      dispatch(
        getOrderStyle(
          {
            orderId,
            styleId: selectedStyleId,
          },
          setIsStyleDetailsLoading,
          onSuccessGetOrderStyle
        )
      );
    }
  }, [selectedStyleId]);

  const getLabelName = (style: Style) => {
    return `#${style.number} ${
      style.name
    } ${style.productLine?.name.toUpperCase()} ${
      style.woodMaterial?.name ?? style.materialGroup?.name
    }, ${style.finishColor?.name ?? style.materialColor?.name}, ${
      style.doorStyle?.name ?? 'N/A'
    }`;
  };

  return (
    <>
      <CurtainHeader flex middle between>
        <H3>Style Details</H3>
        <CloseButton onClick={onClear}>
          <CloseIcon />
        </CloseButton>
      </CurtainHeader>
      {isStyleDetailsLoading && (
        <LoaderFullScreen flex middle center top={72} bottom={71}>
          <Loader size={40} />
        </LoaderFullScreen>
      )}
      {!selectedStyle && !isStyleDetailsLoading && (
        <Wrapper flex wFull center>
          <Wrapper width={90} center>
            <Spacer h="24px" />
            <Wrapper middle>
              <P fontSize={12} textAlign="center" bold>
                Please select a style to see its details.
              </P>
            </Wrapper>
            <Spacer h="24px" />
            <Select
              options={orderStyles?.map((item) => {
                return { value: item.id, label: getLabelName(item) };
              })}
              onChange={(val: { value: string; label: string }) =>
                setSelectedStyleId(val.value)
              }
              placeholder="Style"
            />
          </Wrapper>
        </Wrapper>
      )}

      {selectedStyle && (
        <>
          <Wrapper style={{ backgroundColor: wildSandTwo, padding: '16px' }}>
            <P bold>{selectedStyle && getLabelName(selectedStyle)}</P>
          </Wrapper>
          <ScrollbarsCustom
            autoHideTimeout={500}
            autoHideDuration={300}
            paddingRight={0}
            autoHide={false}
          >
            <Container flex column>
              {selectedStyle.note && (
                <Wrapper>
                  <StyleDetailsField
                    fieldLabel="STYLE NOTES"
                    fieldName={FieldName.StyleNote}
                    styleId={selectedStyle.id!}
                    fieldValue={selectedStyle.note}
                    asNote
                  />

                  <Divider />
                  <Spacer h="16px" />
                </Wrapper>
              )}
              <Wrapper flex wFull column>
                <Wrapper flex wFull between>
                  <Wrapper>
                    <StyleDetailsField
                      fieldLabel={config.styleSpecifications.woodLabel.toUpperCase()}
                      fieldName={FieldName.StyleWoodMaterial}
                      styleId={selectedStyle.id!}
                      fieldValue={
                        selectedStyle.woodMaterial?.name ??
                        selectedStyle.materialGroup.name
                      }
                    />
                  </Wrapper>

                  {!!selectedStyle.woodEffects?.length && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="WOOD EFFECT(S)"
                        fieldName={FieldName.StyleWoodEffect}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.woodEffects
                          .reduce((agg, woodEffect) => {
                            agg.push(woodEffect.name ?? '');
                            return agg;
                          }, [] as string[])
                          .filter((x) => x)
                          .join(', ')}
                      />
                    </Wrapper>
                  )}
                </Wrapper>

                <Wrapper flex wFull between>
                  {config.styleSpecifications.showEngineeredCheckbox && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="ENGINEERED FURNITURE BOARD CONSTRUCTION"
                        fieldName={
                          FieldName.StyleEngineeredFurnitureBoardConstruction
                        }
                        styleId={selectedStyle.id!}
                        fieldValue={
                          selectedStyle.engineeredFurnitureBoardConstruction
                            ? 'ON'
                            : 'OFF'
                        }
                      />
                    </Wrapper>
                  )}

                  {config.styleSpecifications.showWoodInterior &&
                    selectedStyle.woodInterior?.name && (
                      <Wrapper>
                        <StyleDetailsField
                          fieldLabel="WOOD INTERIOR"
                          fieldName={FieldName.StyleWoodInterior}
                          styleId={selectedStyle.id!}
                          fieldValue={selectedStyle.woodInterior?.name}
                        />
                      </Wrapper>
                    )}
                </Wrapper>

                <Wrapper flex wFull between>
                  {config.styleSpecifications.showSelectWoodSpecies &&
                    selectedStyle.selectWoodSpecies && (
                      <Wrapper>
                        <StyleDetailsField
                          fieldLabel="SELECT WOOD SPECIES"
                          fieldName={FieldName.StyleSelectWoodSpecies}
                          styleId={selectedStyle.id!}
                          fieldValue={selectedStyle.selectWoodSpecies}
                        />
                      </Wrapper>
                    )}

                  {config.styleSpecifications.showCabinetBoxMaterial &&
                    selectedStyle.cabinetBoxMaterial?.name && (
                      <Wrapper>
                        <StyleDetailsField
                          fieldLabel="CABINET BOX MATERIAL"
                          fieldName={FieldName.StyleCabinetBoxMaterial}
                          styleId={selectedStyle.id!}
                          fieldValue={selectedStyle.cabinetBoxMaterial?.name}
                        />
                      </Wrapper>
                    )}
                </Wrapper>

                <Wrapper flex wFull between>
                  {config.styleSpecifications.showCabinetBoxMaterial &&
                    selectedStyle.specialCabinetBoxMaterial &&
                    selectedStyle.cabinetBoxMaterial?.name ===
                      CabinetBoxMaterialOptionsEnum.SPECIAL && (
                      <Wrapper>
                        <StyleDetailsField
                          fieldLabel="SPECIAL CABINET BOX MATERIAL"
                          fieldName={FieldName.StyleSpecialCabinetBoxMaterial}
                          styleId={selectedStyle.id!}
                          fieldValue={selectedStyle.specialCabinetBoxMaterial}
                        />
                      </Wrapper>
                    )}

                  {config.styleSpecifications.showEdgebandColor &&
                    selectedStyle.edgebandColor && (
                      <Wrapper>
                        <StyleDetailsField
                          fieldLabel="EDGE BAND COLOR"
                          fieldName={FieldName.StyleEdgebandColor}
                          styleId={selectedStyle.id!}
                          fieldValue={selectedStyle.edgebandColor}
                        />
                      </Wrapper>
                    )}
                </Wrapper>

                <Wrapper flex wFull between>
                  {config.styleSpecifications.showSQFT &&
                    selectedStyle.doorSquareFootPrice &&
                    selectedStyle.doorSquareFootPrice !== undefined && (
                      <Wrapper>
                        <StyleDetailsField
                          fieldLabel="SQFT $"
                          fieldName={FieldName.StyleSQFT}
                          styleId={selectedStyle.id!}
                          fieldValue={selectedStyle.doorSquareFootPrice}
                        />
                      </Wrapper>
                    )}

                  {config.styleSpecifications.showSQFT &&
                    selectedStyle.miDoorSquareFootPrice && (
                      <Wrapper>
                        <StyleDetailsField
                          fieldLabel="MATCHING INT. SQFT $"
                          fieldName={FieldName.StyleMatchingInteriorSQFT}
                          styleId={selectedStyle.id!}
                          fieldValue={selectedStyle.miDoorSquareFootPrice}
                        />
                      </Wrapper>
                    )}
                </Wrapper>

                <Wrapper flex wFull between>
                  {config.styleSpecifications.showVendorColor &&
                    selectedStyle.vendorColor?.name && (
                      <Wrapper>
                        <StyleDetailsField
                          fieldLabel="VENDOR COLOR NAME"
                          fieldName={FieldName.StyleVendorColorName}
                          styleId={selectedStyle.id!}
                          fieldValue={selectedStyle.vendorColor?.name}
                        />
                      </Wrapper>
                    )}

                  {config.styleSpecifications.showVendorColor &&
                    selectedStyle.vendorColor?.code && (
                      <Wrapper>
                        <StyleDetailsField
                          fieldLabel="VENDOR COLOR CODE"
                          fieldName={FieldName.StyleVendorColorCode}
                          styleId={selectedStyle.id!}
                          fieldValue={selectedStyle.vendorColor?.code}
                        />
                      </Wrapper>
                    )}
                </Wrapper>
              </Wrapper>

              {selectedStyle.woodNotes && (
                <Wrapper>
                  <StyleDetailsField
                    fieldLabel="WOOD NOTES"
                    fieldName={FieldName.StyleWoodNotes}
                    styleId={selectedStyle.id!}
                    fieldValue={selectedStyle.woodNotes}
                    asNote
                  />
                </Wrapper>
              )}
              <Wrapper>
                <Divider />
                <Spacer h="16px" />
              </Wrapper>

              <Wrapper flex wFull column>
                <Wrapper flex wFull between>
                  {selectedStyle.finishColor?.name && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="FINISH COLOR"
                        fieldName={FieldName.StyleFinishColor}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.finishColor?.name}
                      />
                    </Wrapper>
                  )}

                  <Wrapper>
                    <OrderFinishLabel
                      style={selectedStyle}
                      render={(label) => (
                        <OrderFinishValue
                          style={selectedStyle}
                          defaultValue=""
                          render={(value) => (
                            <StyleDetailsField
                              fieldLabel={label.toUpperCase()}
                              fieldName={FieldName.StyleFinishColor}
                              styleId={selectedStyle.id!}
                              fieldValue={value}
                            />
                          )}
                        />
                      )}
                    />
                  </Wrapper>
                </Wrapper>

                <Wrapper flex wFull between>
                  {selectedStyle.varnish?.name && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="VARNISH SHEEN"
                        fieldName={FieldName.StyleVarnish}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.varnish?.name}
                      />
                    </Wrapper>
                  )}

                  {selectedStyle.grainDirection?.name && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="GRAIN DIRECTION"
                        fieldName={FieldName.StyleGrainDirection}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.grainDirection?.name}
                      />
                    </Wrapper>
                  )}
                </Wrapper>
                <Wrapper flex wFull between>
                  {!!selectedStyle.finishEffects?.length && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="FINISH EFFECT(S)"
                        fieldName={FieldName.StyleFinishEffect}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.finishEffects
                          .reduce((agg, finishEffect) => {
                            agg.push(finishEffect.name ?? '');
                            return agg;
                          }, [] as string[])
                          .filter((x) => x)
                          .join(', ')}
                      />
                    </Wrapper>
                  )}

                  {config.styleSpecifications.showSpecialFinishSample &&
                    selectedStyle.specialFinishSample && (
                      <Wrapper>
                        <StyleDetailsField
                          fieldLabel="SPECIAL FINISH SAMPLE"
                          fieldName={FieldName.StyleSpecialFinishSample}
                          styleId={selectedStyle.id!}
                          fieldValue={selectedStyle.specialFinishSample}
                        />
                      </Wrapper>
                    )}
                </Wrapper>
              </Wrapper>

              {selectedStyle.finishNotes && (
                <Wrapper>
                  <StyleDetailsField
                    fieldLabel="FINISH NOTES"
                    fieldName={FieldName.StyleFinishNotes}
                    styleId={selectedStyle.id!}
                    fieldValue={selectedStyle.finishNotes}
                    asNote
                  />
                </Wrapper>
              )}

              <Wrapper>
                <Divider />
                <Spacer h="16px" />
              </Wrapper>

              <Wrapper flex wFull column>
                <Wrapper flex wFull between>
                  {selectedStyle.doorOverlay?.name && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="DOOR OVERLAY"
                        fieldName={FieldName.StyleDoorOverlay}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.doorOverlay?.name}
                      />
                    </Wrapper>
                  )}
                  {selectedStyle.faceFrame?.name && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="FACE FRAME"
                        fieldName={FieldName.StyleFaceFrame}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.faceFrame?.name}
                      />
                    </Wrapper>
                  )}
                </Wrapper>
                <Wrapper flex wFull between>
                  {selectedStyle.frameStyle?.name && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="FRAME STYLE"
                        fieldName={FieldName.StyleFrameStyle}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.frameStyle?.name}
                      />
                    </Wrapper>
                  )}
                  {selectedStyle.doorEdgeBand?.name && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="DOOR EDGE BAND"
                        fieldName={FieldName.StyleDoorEdgeBand}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.doorEdgeBand?.name}
                      />
                    </Wrapper>
                  )}
                </Wrapper>
                <Wrapper flex wFull between>
                  {config.doorBuilder.showOneInchDoor && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel='1" DOOR'
                        fieldName={FieldName.StyleOneInchDoor}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.oneInchDoor ? 'YES' : 'NO'}
                      />
                    </Wrapper>
                  )}

                  {config.doorBuilder.showNoMidrails && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="MIDRAILS"
                        fieldName={FieldName.StyleNoMidrails}
                        styleId={selectedStyle.id!}
                        fieldValue={
                          selectedStyle.noMidrails ? 'No Midrails' : 'Midrails'
                        }
                      />
                    </Wrapper>
                  )}
                </Wrapper>

                <Wrapper flex wFull between>
                  {config.doorBuilder.showMetalFinish &&
                    selectedStyle.metalFinish?.name && (
                      <Wrapper>
                        <StyleDetailsField
                          fieldLabel="METAL FINISH"
                          fieldName={FieldName.StyleMetalFinish}
                          styleId={selectedStyle.id!}
                          fieldValue={selectedStyle.metalFinish?.name}
                        />
                      </Wrapper>
                    )}

                  {config.doorBuilder.showDrawerTopPanel &&
                    selectedStyle.topInsertPanel?.name && (
                      <Wrapper>
                        <StyleDetailsField
                          fieldLabel="TOP DRAWER INSERT PANEL"
                          fieldName={FieldName.StyleTopInsertPanel}
                          styleId={selectedStyle.id!}
                          fieldValue={selectedStyle.topInsertPanel?.name}
                        />
                      </Wrapper>
                    )}
                </Wrapper>

                <Wrapper flex wFull between>
                  {selectedStyle.drawerBox?.name && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="DRAWER BOX"
                        fieldName={FieldName.StyleDrawerBox}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.drawerBox?.name}
                      />
                    </Wrapper>
                  )}
                  {selectedStyle.hdfPanels !== undefined && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="HDF PANELS"
                        fieldName={FieldName.StyleHdfPanels}
                        styleId={selectedStyle.id!}
                        fieldValue={
                          selectedStyle.hdfPanels === false ? 'NO' : 'YES'
                        }
                      />
                    </Wrapper>
                  )}
                </Wrapper>
                <Wrapper flex wFull between>
                  {config.doorBuilder.showOneInchFaceFrame && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel='1" FACE FRAME'
                        fieldName={FieldName.StyleOneInchFaceFrame}
                        styleId={selectedStyle.id!}
                        fieldValue={
                          selectedStyle.oneInchFaceFrame === false
                            ? 'NO'
                            : 'YES'
                        }
                      />
                    </Wrapper>
                  )}

                  {selectedStyle.toeSpaceType?.name && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="TOE SPACE TYPE"
                        fieldName={FieldName.StyleToeSpaceType}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.toeSpaceType?.name}
                      />
                    </Wrapper>
                  )}
                </Wrapper>
              </Wrapper>

              {config.styleSpecifications.showInteriorNotes &&
                selectedStyle.interiorNotes && (
                  <Wrapper>
                    <StyleDetailsField
                      fieldLabel="INTERIOR NOTES"
                      fieldName={FieldName.StyleInteriorNotes}
                      styleId={selectedStyle.id!}
                      fieldValue={selectedStyle.interiorNotes}
                      asNote
                    />
                  </Wrapper>
                )}

              {selectedStyle.materialNotes && (
                <Wrapper>
                  <StyleDetailsField
                    fieldLabel="MATERIAL NOTES"
                    fieldName={FieldName.StyleMaterialNotes}
                    styleId={selectedStyle.id!}
                    fieldValue={selectedStyle.materialNotes}
                    asNote
                  />
                </Wrapper>
              )}

              <Wrapper>
                <Divider />
                <Spacer h="16px" />
              </Wrapper>

              <Wrapper flex column>
                <Wrapper flex wFull>
                  <Spacer w="140px" />
                  <Wrapper flex between wFull>
                    <Wrapper widthPX={80}>
                      <P color={silverChaliceTwo} fontSize={14}>
                        WALL
                      </P>
                    </Wrapper>

                    <Wrapper widthPX={60}>
                      <P color={silverChaliceTwo} fontSize={14}>
                        BASE
                      </P>
                    </Wrapper>

                    <Wrapper widthPX={100}>
                      <P color={silverChaliceTwo} fontSize={14}>
                        DRAWER
                      </P>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>

                <>
                  <Spacer h="16px" />

                  <Wrapper flex wFull>
                    <Wrapper widthPX={120}>
                      <P color={silverChaliceTwo} fontSize={14}>
                        DOOR STYLE
                      </P>
                    </Wrapper>

                    <Wrapper flex justifyStart>
                      {selectedStyle.wallDoorStyle?.name && (
                        <Wrapper widthPX={80}>
                          <PSmall textAlign="center" bold>
                            {selectedStyle.wallDoorStyle?.name}
                          </PSmall>
                        </Wrapper>
                      )}

                      {selectedStyle.baseDoorStyle?.name && (
                        <Wrapper widthPX={80}>
                          <PSmall textAlign="center">
                            {selectedStyle.baseDoorStyle?.name}
                          </PSmall>
                        </Wrapper>
                      )}

                      {(selectedStyle.drawerFrontStyle?.name ||
                        selectedStyle.materialDrawer?.name) && (
                        <Wrapper widthPX={80}>
                          <PSmall wordBreak="break-all" textAlign="center">
                            {selectedStyle.drawerFrontStyle?.name ??
                              selectedStyle.materialDrawer?.name}
                          </PSmall>
                        </Wrapper>
                      )}
                    </Wrapper>
                  </Wrapper>

                  {(selectedStyle.wallDoorConfiguration?.name ||
                    selectedStyle.baseDoorConfiguration?.name) && (
                    <>
                      <Spacer h="16px" />
                      <Wrapper flex wFull>
                        <Wrapper widthPX={120}>
                          <P color={silverChaliceTwo} fontSize={14}>
                            CONFIGURATION
                          </P>
                        </Wrapper>

                        <Wrapper flex justifyStart>
                          {selectedStyle.wallDoorConfiguration?.name && (
                            <Wrapper widthPX={80}>
                              <PSmall wordBreak="break-all" textAlign="center">
                                {selectedStyle.wallDoorConfiguration?.name}
                              </PSmall>
                            </Wrapper>
                          )}

                          {selectedStyle.baseDoorConfiguration?.name && (
                            <Wrapper widthPX={80}>
                              <PSmall wordBreak="break-all" textAlign="center">
                                {selectedStyle.baseDoorConfiguration?.name}
                              </PSmall>
                            </Wrapper>
                          )}

                          <Spacer w="15px" />

                          <PSmall />
                        </Wrapper>
                      </Wrapper>
                    </>
                  )}

                  {(selectedStyle.wallDoorInsertPanel?.name ||
                    selectedStyle.baseDoorInsertPanel?.name ||
                    selectedStyle.drawerInsertPanel?.name) && (
                    <>
                      <Spacer h="16px" />
                      <Wrapper flex wFull>
                        <Wrapper widthPX={120}>
                          <P color={silverChaliceTwo} fontSize={14}>
                            INSERT PANEL
                          </P>
                        </Wrapper>
                        <Wrapper flex justifyStart>
                          {selectedStyle.wallDoorInsertPanel?.name && (
                            <Wrapper widthPX={80}>
                              <PSmall wordBreak="break-all" textAlign="center">
                                {selectedStyle.wallDoorInsertPanel?.name}
                              </PSmall>
                            </Wrapper>
                          )}

                          {selectedStyle.baseDoorInsertPanel?.name && (
                            <Wrapper widthPX={80}>
                              <PSmall wordBreak="break-all" textAlign="center">
                                {selectedStyle.baseDoorInsertPanel?.name}
                              </PSmall>
                            </Wrapper>
                          )}

                          {selectedStyle.drawerInsertPanel?.name && (
                            <Wrapper widthPX={80}>
                              <PSmall wordBreak="break-all" textAlign="center">
                                {selectedStyle.drawerInsertPanel?.name}
                              </PSmall>
                            </Wrapper>
                          )}
                        </Wrapper>
                      </Wrapper>
                    </>
                  )}

                  <Spacer h="16px" />
                  <Wrapper flex wFull>
                    <Wrapper widthPX={120}>
                      <P color={silverChaliceTwo} fontSize={14}>
                        FRAME SIZE
                      </P>
                    </Wrapper>
                    <Wrapper flex justifyStart>
                      {selectedStyle.customFrameSize ? (
                        <Wrapper widthPX={80}>
                          <PSmall wordBreak="break-all" textAlign="center">
                            {selectedStyle?.productLine.name ===
                            ProductLineEnums.PRODUCT_LINE_PRELUDE
                              ? `${selectedStyle?.customFrameSize} + $21`
                              : selectedStyle?.customFrameSize.toString()}
                          </PSmall>
                        </Wrapper>
                      ) : (
                        <Wrapper widthPX={80}>
                          <PSmall wordBreak="break-all" textAlign="center">
                            {selectedStyle?.frameSize?.upcharge
                              ? `${selectedStyle?.frameSize?.size} + ${selectedStyle?.frameSize?.upcharge}`
                              : selectedStyle?.frameSize?.size?.toString()}
                          </PSmall>
                        </Wrapper>
                      )}
                    </Wrapper>
                  </Wrapper>

                  {selectedStyle.archStyle?.name && (
                    <>
                      <Spacer h="16px" />

                      <Wrapper flex wFull>
                        <Wrapper widthPX={120}>
                          <P color={silverChaliceTwo} fontSize={14}>
                            ARCH STYLE
                          </P>
                        </Wrapper>
                        <Wrapper flex justifyStart>
                          <Wrapper widthPX={80}>
                            <PSmall wordBreak="break-all" textAlign="center">
                              {selectedStyle.archStyle?.name}
                            </PSmall>
                          </Wrapper>
                        </Wrapper>
                      </Wrapper>
                    </>
                  )}

                  {(selectedStyle.wallDoorEdge?.name ||
                    selectedStyle.baseDoorEdge?.name ||
                    selectedStyle.drawerEdge?.name) && (
                    <>
                      <Spacer h="16px" />
                      <Wrapper flex wFull>
                        <Wrapper widthPX={120}>
                          <P color={silverChaliceTwo} fontSize={14}>
                            DOOR EDGE
                          </P>
                        </Wrapper>
                        <Wrapper flex justifyStart>
                          {selectedStyle.wallDoorEdge?.name && (
                            <Wrapper widthPX={80}>
                              <PSmall wordBreak="break-all" textAlign="center">
                                {selectedStyle.wallDoorEdge?.name}
                              </PSmall>
                            </Wrapper>
                          )}

                          {selectedStyle.baseDoorEdge?.name && (
                            <Wrapper widthPX={80}>
                              <PSmall wordBreak="break-all" textAlign="center">
                                {selectedStyle.baseDoorEdge?.name}
                              </PSmall>
                            </Wrapper>
                          )}

                          {selectedStyle.drawerEdge?.name && (
                            <Wrapper widthPX={80}>
                              <PSmall wordBreak="break-all" textAlign="center">
                                {selectedStyle.drawerEdge?.name}
                              </PSmall>
                            </Wrapper>
                          )}
                        </Wrapper>
                      </Wrapper>
                      <Spacer h="16px" />
                    </>
                  )}
                </>
              </Wrapper>

              {selectedStyle.doorNotes && (
                <Wrapper>
                  <StyleDetailsField
                    fieldLabel="DOOR NOTES"
                    fieldName={FieldName.StyleDoorNotes}
                    styleId={selectedStyle.id!}
                    fieldValue={selectedStyle.doorNotes}
                    asNote
                  />
                </Wrapper>
              )}
              <Wrapper>
                <Divider />
                <Spacer h="16px" />
              </Wrapper>

              <Wrapper flex wFull column>
                <Wrapper flex wFull between>
                  {selectedStyle.doorHardware?.name && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="DOOR HARDWARE"
                        fieldName={FieldName.StyleDoorHardware}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.doorHardware?.name}
                      />
                    </Wrapper>
                  )}

                  {selectedStyle.hingeType?.name && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="HINGE TYPE"
                        fieldName={FieldName.StyleHingeType}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.hingeType?.name}
                      />
                    </Wrapper>
                  )}
                </Wrapper>
                <Wrapper flex wFull between>
                  {selectedStyle.drawerHardware?.name && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="DRAWER HARDWARE"
                        fieldName={FieldName.StyleDrawerHardware}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.drawerHardware?.name}
                      />
                    </Wrapper>
                  )}

                  {selectedStyle.hingeColor?.name && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="HINGE COLOR"
                        fieldName={FieldName.StyleHingeColor}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.hingeColor?.name}
                      />
                    </Wrapper>
                  )}
                </Wrapper>

                {config.doorBuilder.showClosetHardwareColor &&
                  selectedStyle.closetHardwareColor?.name && (
                    <Wrapper>
                      <StyleDetailsField
                        fieldLabel="CLOSET HARDWARE COLOR"
                        fieldName={FieldName.StyleClosetHardwareColor}
                        styleId={selectedStyle.id!}
                        fieldValue={selectedStyle.closetHardwareColor?.name}
                      />
                    </Wrapper>
                  )}
              </Wrapper>

              {selectedStyle.hardwareNotes && (
                <Wrapper>
                  <StyleDetailsField
                    fieldLabel="HARDWARE NOTES"
                    fieldName={FieldName.StyleHardwareNotes}
                    styleId={selectedStyle.id!}
                    fieldValue={selectedStyle.hardwareNotes}
                    asNote
                  />
                </Wrapper>
              )}
            </Container>
          </ScrollbarsCustom>
        </>
      )}
    </>
  );
};
