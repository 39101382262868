import styled from 'styled-components';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as PlusOutlinedIcon } from 'assets/icons/plus-outlined.svg';

import { CurtainTopicList } from 'curtain/components/CurtainTopicList';
import { TopicType } from 'curtain/enums/TopicTypeEnum';
import { RadioButton } from 'shared/components/RadioButton';

import { TopicFilterEnums } from 'curtain/enums/TopicFilterEnums';

import {
  setActiveChannel,
  setActiveEntityID,
  setActiveEntityName,
  setActiveLineItemID,
  setActiveStyleID,
  setBackToChanel,
  setCreateTopic,
  setTopicItemDefaultValue,
  setTopicTypeDefaultValue,
  setTopicsFilter,
  setUpdatedComment,
  setUpdatedTopic,
} from 'curtain/store/curtainActions';

import SVG from 'shared/components/SVG';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import { ButtonIcon } from 'shared/components/Button';
import { H3 } from 'shared/components/Typography';
import { RootState } from 'store';

import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { black } from 'shared/config/Colors';
import { orderHeaderHeightWithCollaborationActions } from 'shared/config/Variables';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useCanEditOrder } from 'shared/hooks/useCanEditOrder';

import { useResetActiveEntity } from 'shared/hooks/useResetActiveEntity';
import CollaborationActions from './CollaborationActions';

const ChannelContainer = styled(Wrapper)<{ canEditOrder: boolean }>`
  // Total page height minus header and footer heights plus bottom-border height
  height: calc(
    100vh -
      ${(canEditOrder) =>
        orderHeaderHeightWithCollaborationActions - (canEditOrder ? 0 : 44)}px
  );
  overflow: auto;
`;

const ChannelHeader = styled(Wrapper)`
  padding: 14px;
  min-height: 65px;
  border-bottom: 1px solid ${({ theme }) => theme.alto};
`;

const ChannelSubHeader = styled(Wrapper)`
  background-color: ${({ theme }) => theme.wildSandTwo};
  border-bottom: 1px solid ${({ theme }) => theme.alto};
  padding: 14px 20px;
  min-height: 65px;

  h3 {
    font-weight: 700;
  }
`;

export const CurtainChannelTopics = () => {
  const dispatch = useAppDispatch();

  const canEditOrder = useCanEditOrder();

  const topics = useSelector((state: RootState) => state.curtainReducer.topics);

  const activeEntityType = useSelector(
    (state: RootState) => state.curtainReducer.activeEntityType
  );

  const activeEntityID = useSelector(
    (state: RootState) => state.curtainReducer.activeEntityID
  );

  const activeEntityName = useSelector(
    (state: RootState) => state.curtainReducer.activeEntityName
  );

  const activeChannel = useSelector(
    (state: RootState) => state.curtainReducer.activeChannel
  );

  const topicsFilter = useSelector(
    (state: RootState) => state.curtainReducer.topicsFilter
  );
  const createTopic = useSelector(
    (state: RootState) => state.curtainReducer.createTopic
  );

  const resetCurtain = () => {
    dispatch(setActiveChannel(null));
    dispatch(setActiveEntityID(null));
    dispatch(setActiveEntityName(null));
    dispatch(setActiveLineItemID(null));
    dispatch(setActiveStyleID(null));
    dispatch(setUpdatedComment(null));
    dispatch(setUpdatedTopic(null));
  };

  const resetActiveEntityType = useResetActiveEntity();

  const goBack = () => {
    resetCurtain();
    dispatch(setBackToChanel(null));
    resetActiveEntityType();
  };

  const onTopicFilterChange = (val: TopicFilterEnums) => {
    dispatch(setTopicsFilter(val as TopicFilterEnums));
  };

  useEffect(() => {
    return () => {
      if (createTopic) {
        resetCurtain();
      }
    };
  }, [createTopic]);

  useEffect(() => {
    dispatch(setTopicsFilter(TopicFilterEnums.UNRESOLVED));
  }, []);

  return (
    <ChannelContainer flex column canEditOrder={canEditOrder}>
      <ChannelHeader flex middle between>
        <Wrapper flex middle>
          <ButtonIcon onClick={goBack}>
            <ArrowLeftIcon />
          </ButtonIcon>
          <Spacer w="16px" />
          <H3>Topics</H3>
        </Wrapper>
        <Wrapper flex middle>
          <RadioButton
            checked={topicsFilter === TopicFilterEnums.UNRESOLVED}
            onChange={() => onTopicFilterChange(TopicFilterEnums.UNRESOLVED)}
            id={`topic-filter-${TopicFilterEnums.UNRESOLVED}`}
            fontSize="12px"
            title={`(${
              topics?.filter((item) => !item.isResolved && !item.isDeleted)
                .length
            }) Unresolved`}
          />

          <Spacer w="10px" />
          <RadioButton
            id={`topic-filter-${TopicFilterEnums.RESOLVED}`}
            checked={topicsFilter === TopicFilterEnums.RESOLVED}
            onChange={() => onTopicFilterChange(TopicFilterEnums.RESOLVED)}
            fontSize="12px"
            title={`(${
              topics?.filter((item) => item.isResolved).length
            }) Resolved`}
          />
          <Spacer w="10px" />
        </Wrapper>
      </ChannelHeader>

      <ChannelSubHeader flex middle between>
        <H3>
          {activeEntityName?.length ? activeEntityName : activeChannel?.name}
        </H3>

        {!activeChannel?.isDeleted && (
          <ButtonIcon
            onClick={() => {
              dispatch(setCreateTopic(true));

              if (activeEntityType === TopicType.STYLE) {
                dispatch(
                  setTopicTypeDefaultValue({
                    value: TopicType.STYLE,
                    label: 'Style',
                  })
                );
              }

              if (activeEntityType === TopicType.LINE_ITEM) {
                dispatch(
                  setTopicTypeDefaultValue({
                    value: TopicType.LINE_ITEM,
                    label: 'Line Item',
                  })
                );
              }

              if ((activeEntityID && activeEntityName) || activeChannel) {
                dispatch(
                  setTopicItemDefaultValue({
                    value: activeEntityID ?? activeChannel?.entityId,
                    label: activeEntityName ?? activeChannel?.name,
                  })
                );
              }
            }}
          >
            <SVG icon={<PlusOutlinedIcon />} color={black} />
          </ButtonIcon>
        )}
      </ChannelSubHeader>

      <ScrollbarsCustom
        autoHide
        autoHideTimeout={500}
        autoHideDuration={300}
        paddingRight={0}
      >
        <CurtainTopicList />
      </ScrollbarsCustom>

      <CollaborationActions />
    </ChannelContainer>
  );
};
