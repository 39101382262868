import { FC, MouseEvent } from 'react';
import styled from 'styled-components';

import { Priority } from 'order/enums/priorityEnum';

import { H5, P } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { PriorityBadge } from 'shared/components/PriorityBadge';
import HangingNote from 'shared/components/HangingNote';
import { NewCollaborationBadge } from 'shared/components/NewCollaborationBadge';
import { Badge } from 'shared/components/Badge';
import { poloBlue } from 'shared/config/Colors';

interface ChannelCardProps {
  id: string;
  name: string;
  isDeleted: boolean;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
  priority: Priority;
  changeRequested: boolean;
  isRead: boolean;
  isUserCSR: boolean | undefined;
  dealerRead: boolean;
  drawingDetails?: {
    perDrawing: boolean;
    perFactoryDrawing: boolean;
  };
}

const ChannelCardContainer = styled(Wrapper)<
  Pick<ChannelCardProps, 'isDeleted'>
>`
  min-height: 52px;
  border-bottom: 1px solid ${({ theme }) => theme.alto};
  padding-left: 20px;
  transition: 300ms ease;
  flex-wrap: wrap;
  padding: 15px 5px;
  gap: 5px;

  ${({ isDeleted }) =>
    isDeleted &&
    `
    > h5 {
      opacity: 0.5;
    }
  `}

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.lynch};
  }
`;

const DraftLabel = styled.span`
  opacity: 0.5;
  padding-left: 5px;
`;

export const ChannelCard: FC<ChannelCardProps> = ({
  id,
  name,
  isDeleted,
  onClick,
  priority,
  changeRequested,
  isRead,
  isUserCSR,
  dealerRead,
  drawingDetails,
}) => {
  const getDraftLabel = () => {
    const draftKey = `draft_${id}`;
    const newTopicKey = `newtopic_${id}`;
    let hasDraft = false;
    let n = window.sessionStorage.length;
    // eslint-disable-next-line no-plusplus
    while (n--) {
      const key = window.sessionStorage.key(n);
      if (key !== null) {
        if (key.includes(draftKey) || key.includes(newTopicKey)) {
          hasDraft = true;
          break;
        }
      }
    }
    return hasDraft ? <DraftLabel>(Draft)</DraftLabel> : null;
  };

  const showCollaborationBadge = () => {
    return (!isRead && isUserCSR) || (!dealerRead && !isUserCSR);
  };

  return (
    <ChannelCardContainer flex middle onClick={onClick} isDeleted={isDeleted}>
      <H5>
        {name}
        {isDeleted && ' (Deleted)'}
        {getDraftLabel()}
      </H5>

      {changeRequested && <HangingNote space="left" />}

      {!isDeleted && <PriorityBadge priority={priority} />}
      {showCollaborationBadge() && !isDeleted && (
        <>
          <NewCollaborationBadge />
        </>
      )}
      {drawingDetails?.perFactoryDrawing && !isDeleted && (
        <Badge bgColor={poloBlue}>
          <P color="white" fontSize={12} bold>
            PF
          </P>
        </Badge>
      )}
      {drawingDetails?.perDrawing && !isDeleted && (
        <Badge>
          <P color="white" fontSize={12} bold>
            PD
          </P>
        </Badge>
      )}
    </ChannelCardContainer>
  );
};
