import { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { ReactComponent as ShoppingCartIcon } from 'assets/icons/shopping-cart.svg';
import { ReactComponent as StyleIcon } from 'assets/icons/style.svg';
import { P } from 'shared/components/Typography';
import { Wrapper } from '../../../shared/components/Wrapper';
import { Spacer } from '../../../shared/components/Layout';
import UtilService from '../../../shared/services/util.service';
import { lynch } from '../../../shared/config/Colors';
import {
  setImportStylesModalFlow,
  setImportStylesModalMeta,
} from '../../wizard/import/store/ImportActions';
import { ImportStylesModalHeaderEnums } from '../../wizard/orderStyles/enums/ImportStylesModalEnum';
import { ImportStylesModalStepsEnums } from '../../wizard/import/enums/ImportStylesModalStepsEnums';
import { useAppDispatch } from '../../../shared/hooks/useAppDispatch';
import { ImportLineItemsFlow } from '../../wizard/orderStyles/enums/ImportLineItemsFlow';
import { RootState } from '../../../store';

const ImportLineItemOption = styled.button`
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.white};
  align-items: center;
  border: 1px solid
    ${({ theme }) => UtilService.colorWithOpacity(theme.silverSand, 0.5)};
  font-size: 14px;
  height: 100%;
  padding: 17px 27px 17px 51px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  min-height: 300px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) =>
      UtilService.colorWithOpacity(theme.silverSand, 0.2)};
    transition: 300ms ease;
  }
`;

const ImportLineItem: FC = () => {
  const dispatch = useAppDispatch();

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const handleChooseImportFlow = (importLineItemFlow: ImportLineItemsFlow) => {
    if (importLineItemFlow === ImportLineItemsFlow.COPY_LINE_ITEMS) {
      dispatch(
        setImportStylesModalMeta({
          desc: ImportStylesModalHeaderEnums.ORDER_SEARCH_DESC,
          orderId: null,
          step: ImportStylesModalStepsEnums.SEARCH_ORDERS,
          title: ImportStylesModalHeaderEnums.ORDER_SEARCH_TITLE,
        })
      );
    }
    if (order && importLineItemFlow === ImportLineItemsFlow.MOVE_LINE_ITEMS) {
      dispatch(
        setImportStylesModalMeta({
          desc: ImportStylesModalHeaderEnums.SELECT_STYLE_DESC,
          orderId: order.id,
          step: ImportStylesModalStepsEnums.SELECT_STYLES,
          title: order.name as ImportStylesModalHeaderEnums,
          previousTitle: order.name,
        })
      );
    }

    dispatch(setImportStylesModalFlow(importLineItemFlow));
  };

  const ImportLineItemCard = (
    label: string,
    IconComponent: React.ElementType,
    importLineItemFlow: ImportLineItemsFlow
  ) => (
    <ImportLineItemOption
      onClick={() => handleChooseImportFlow(importLineItemFlow)}
    >
      <Wrapper flex column middle>
        <IconComponent color={lynch} width="65" height="65" />
        <Spacer h="12px" />
        <P fontSize={16} color={lynch} bold>
          {label}
        </P>
      </Wrapper>
    </ImportLineItemOption>
  );

  return (
    <Wrapper flex middle center>
      {ImportLineItemCard(
        'Copy line items from another order',
        ShoppingCartIcon,
        ImportLineItemsFlow.COPY_LINE_ITEMS
      )}
      <Spacer w="20px" />
      {ImportLineItemCard(
        'Move line items from other style within same order',
        StyleIcon,
        ImportLineItemsFlow.MOVE_LINE_ITEMS
      )}
    </Wrapper>
  );
};

export default ImportLineItem;
